import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import LeftArrow from '../../images/svg/gray-left-arrow.svg';
import RightArrow from '../../images/svg/gray-right-arrow.svg';

import './ResumenSlide.scss';
import ResumenInfo from '../ResumenInfo/ResumenInfo';
import { FunnelData } from '../../interfaces/FunnelInterface';
import { GlobalContext } from '../../context/GlobalState';
import {
	FunnelBg,
	FunnelSlide,
	FunnelSlideContainer,
	FunnelSlideContainerBottom,
	FunnelSlideContainerBottomCenter,
	FunnelSlideContainerBottomLeft,
	FunnelSlideContainerBottomRight,
	FunnelSlideContainerCenter,
	FunnelSlideContainerCenterCenter,
	FunnelSlideContainerCenterLeft,
	FunnelSlideContainerCenterRight,
	FunnelSlideContainerDot,
	FunnelSlideContainerDots,
	FunnelSlideContainerSlide,
	FunnelSlideContainerSlides,
} from './ResumenStyled';

const ResumenSlide = (props: any) => {
	const dotSize = 0.3;
	const dotMargin = 0.5;
	let dot = dotSize + dotMargin * 2;
	let newPosition = 0;

	const [getPos, setPos] = useState(0);
	const { funnel } = useContext(GlobalContext);
	const [funnelData] = useState<FunnelData>(funnel);
	const [autoCount, setAutoCount] = useState(funnelData.step1.cobertura.auto > 0 ? 1 : 0);
	const [motoCount] = useState(funnelData.step1.cobertura.motora > 0 ? 1 : 0);
	const [homeCount, setHomeCount] = useState(funnelData.step1.cobertura.hogar > 0 ? 1 : 0);
	const [productoQty, setProductoQty] = useState(autoCount + motoCount + homeCount);
	const [leftDotPosition, setLeftDotPosition] = useState(0);
	const [rightDotPosition, setRightDotPosition] = useState(dot * (productoQty - newPosition) - dot);

	const dots = productoQty;
	useEffect(() => {
		setAutoCount(funnelData.step1.cobertura.auto > 0 ? 1 : 0);
		setHomeCount(funnelData.step1.cobertura.hogar > 0 ? 1 : 0);
		setProductoQty((funnelData.step1.cobertura.auto > 0 ? 1 : 0) + (funnelData.step1.cobertura.hogar > 0 ? 1 : 0));
		setLeftDotPosition(0)
		setRightDotPosition(dot * ((funnelData.step1.cobertura.auto > 0 ? 1 : 0) + (funnelData.step1.cobertura.hogar > 0 ? 1 : 0) - newPosition) - dot)
	}, [funnelData.step1.cobertura.auto, funnelData.step1.cobertura.hogar]);

	const getProductArray = () => {
		const productArray: any[] = [];
		for (let i = 0; i < productoQty; i++) {
			productArray.push(i);
		}
		return productArray;
	};

	const slideNext = (ev: number) => {
		newPosition = ev;

		dot = dotSize + dotMargin * 2;

		if (newPosition >= 0 && newPosition < productoQty) {
			setLeftDotPosition(dot * newPosition);
			setRightDotPosition(dot * (dots - newPosition) - dot);
			setPos(ev);
		}
	};

	return (
		<FunnelBg>
			<FunnelSlideContainer>
				<FunnelSlideContainerCenter>
					<FunnelSlideContainerCenterCenter>
						<FunnelSlide>
							<FunnelSlideContainerSlides dots={dots} getPos={getPos}>
								{autoCount > 0 && (
									<FunnelSlideContainerSlide dots={dots}>
										<div className="funnel-wrapper">
											<ResumenInfo
												price={props.data?.step1.cobertura.auto}
												category="auto"
												modalClick={props.modalClick}
											/>
										</div>
									</FunnelSlideContainerSlide>
								)}
								{motoCount > 0 && (
									<FunnelSlideContainerSlide dots={dots}>
										<div className="funnel-wrapper">
											<ResumenInfo
												price={props.data?.step1.cobertura.motora}
												category="motora"
												modalClick={props.modalClick}
											/>
										</div>
									</FunnelSlideContainerSlide>
								)}
								{homeCount > 0 && (
									<FunnelSlideContainerSlide dots={dots}>
										<div className="funnel-wrapper">
											<ResumenInfo
												price={props.data?.step1.cobertura.hogar}
												category="hogar"
												modalClick={props.modalClick}
											/>
										</div>
									</FunnelSlideContainerSlide>
								)}
							</FunnelSlideContainerSlides>
						</FunnelSlide>
					</FunnelSlideContainerCenterCenter>
				</FunnelSlideContainerCenter>
				{productoQty > 1 && (
					<FunnelSlideContainerBottom>
						<FunnelSlideContainerBottomLeft>
							<FunnelSlideContainerCenterLeft>
								<Link onClick={() => slideNext(getPos - 1)} to="#">
									<img src={LeftArrow} alt="" />
								</Link>
							</FunnelSlideContainerCenterLeft>
						</FunnelSlideContainerBottomLeft>

						<FunnelSlideContainerBottomCenter>
							<FunnelSlideContainerDots>
								<FunnelSlideContainerDot leftDotPosition={leftDotPosition} rightDotPosition={rightDotPosition} />
								{getProductArray().map(function (d: any, index: number) {
									return (
										<Link key={index} onClick={() => slideNext(index)} to="#" className="funnel-slide-container__dot" />
									);
								})}
							</FunnelSlideContainerDots>
						</FunnelSlideContainerBottomCenter>

						<FunnelSlideContainerBottomRight>
							<FunnelSlideContainerCenterRight>
								<Link onClick={() => slideNext(getPos + 1)} to="#">
									<img src={RightArrow} alt="" />
								</Link>
							</FunnelSlideContainerCenterRight>
						</FunnelSlideContainerBottomRight>
					</FunnelSlideContainerBottom>
				)}
			</FunnelSlideContainer>
		</FunnelBg>
	);
};

export default ResumenSlide;
