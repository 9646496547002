import React, { useContext, useEffect } from 'react';
import './finalizar.scss';
import FunnelHeader from 'components/funnel/header/header';
import { Link, useHistory } from 'react-router-dom';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import DoneImg from 'images/funnel/finalizar.svg';
import { AppContext } from 'helpers/Context';
import { GlobalContext } from 'context/GlobalState';
import { Helmet } from 'react-helmet';
import whatsapp from 'images/svg/wapp.svg';
import llamada from 'images/svg/llamada.svg';
import connectApp from 'images/svg/connect_app.svg';
import { useTranslation } from 'react-i18next';

export default function FunnelFinalizar() {
	const { t } = useTranslation();
	const history = useHistory();
	const { setShowStatus } = useContext(AppContext);
	const { reset } = useContext(GlobalContext);

	const handleClickBtn = () => {
		history.push('/');
	};

	useEffect(() => {
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
	}, [setShowStatus]);

	useEffect(() => {
		reset('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="App">
			<Helmet>
				<title>Finalizar tu compra - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header">
				<FunnelFooterMobile
					className="mobile"
					section="Tu suscripción ha sido creada con éxito"
					btnLabel="listo"
					showChevron={true}
					template={'primary'}
				/>
				<FunnelHeader progress="0" />
			</div>

			<div className="funnel__main__inner">
				<div className="finalizar__container">
					<div className=" illustration">
						<p className="desktop">Tu suscripción ha sido creada con éxito</p>
						<img className="finalizar-direction-column-img" src={DoneImg} alt="" />
					</div>
					<div className=" text text-center-mobile v-ctr">
						<div className="finalizar__margin">
							<h1 className="h1 finalizar__title">¡Listo!</h1>
							<p className="finalizar__p">
								Gracias por completar tu información. Tu suscripción se activará en 72 horas a partir de este momento.
								Te acabamos de enviar un correo electrónico con la confirmación de tu plan y el recibo.
							</p>
							<p className="finalizar__p small">Ya puedes sentirte seguro y respaldado.</p>

							<div className="finalizar__line line"></div>

							<div className="finalizar__contact">
								<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')}>
									<img src={whatsapp} alt="whatsapp" />
								</a>
								<a href={t('phoneLink')} className="spacing">
									<img src={llamada} alt="Logo llamada" />
								</a>
								<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')}>
									<img src={connectApp} alt="Logo connect app" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
