import React, { useContext, useEffect, useState } from 'react';
import SlideContent from 'components/primary-content/primary-content';
import ExperienciaConnect from 'components/experiencia-connect/experiencia-connect';
import Servicios from 'components/home/servicios/servicios';
import NuestrosClientes from 'components/home/nuestros-clientes/nuestros-clientes';

import ExpImg1 from 'images/svg/Tecnologia-Innovadora.svg';
import ExpImg2 from 'images/svg/Cubrimos-Cada-Rincon.svg';
import ExpImg3 from 'images/svg/A-tu-Lado.svg';
import { AppContext } from 'helpers/Context';
import { getFunnelUrl } from 'helpers/FunnelRedirection';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import falabellaLogoWhite from 'images/svg/fulcro-white.svg';
import logo_connect_home from 'images/svg/logo_connect_home.svg';
import falabella_logo from 'images/svg/fulcro__logo__home.svg';
import { FunnelData } from 'interfaces/FunnelInterface';
import './home.scss';
import { GlobalContext } from 'context/GlobalState';

export default function Home() {
	const { t } = useTranslation();
	const { funnel, setFunnel, reset } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const title1 = 'Tecnología innovadora <br />a tu servicio';
	const subTitle1 =
		'Gracias al uso de herramientas tecnológicas pioneras y al smart dispatch system, Connect te garantiza un despacho rápido, ofreciéndote información de progreso en tiempo real.';
	const title2 = 'Cubrimos todo <br />Puerto Rico';
	const subTitle2 = 'Nuestra red de colaboradores <br />está a tu disposición en la <br />carretera 24/7.';
	const title3 = 'A tu lado en <br />todo momento';
	const subTitle3 =
		'La seguridad, transparencia y velocidad van de la mano. Con los tiempos de respuestas más rápidos del mercado, asistencia policial e información de tracking y de chófer, te garantizamos que nunca estarás solo.';
	const nuestrosClienteJson = {};

	const { identify, setIdentifyStatus } = useContext(AppContext);
	const { hash } = useLocation();

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (hash) {
			setTimeout(() => {
				const element = document.querySelector('#servicios');
				element?.scrollIntoView({ behavior: 'smooth' });
			}, 500);
		}
	}, [hash]);
	const setPlan = (planId: string, productType: 'autos' | 'hogar') => {
		funnelData.step1.productType = productType;
		funnelData.step1.plan = planId;
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};
	const goToSection = () => {
		const element = document.querySelector('#servicios');
		element?.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<div className="App">
			<Helmet>
				<title>Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<SlideContent title={t('slideTitle')} subTitle={t('slideSubTitle')} />
			<div className="by__connect__container">
				<div className="by__connect__content">
					<div className="by__connect__logos ">
						<img className="fulcro" src={falabella_logo} alt="Logo fulcro" />
					</div>
					<div>
						<p className="by__connect__text">
							Alianza enfocada en ayudar personas, resolviendo problemas ordinarios con soluciones extraordinarias a
							través del equipo correcto, tecnología e innovación centradas en el usuario.
						</p>
						<Link to={'/cobertura'} className="button">
							Comprar plan
						</Link>
					</div>
				</div>
			</div>
			<div className="experiencia-connect">
				<div className="experiencia-connect-content">
					<ExperienciaConnect title={title1} subTitle={subTitle1} reverse={false} img={ExpImg1} />
					<ExperienciaConnect title={title2} subTitle={subTitle2} reverse={true} img={ExpImg2} />
					<ExperienciaConnect title={title3} subTitle={subTitle3} reverse={false} img={ExpImg3} />
				</div>
				<div className="container__btns">
					<Link to="/cobertura" className="button ctr" onClick={() => setPlan('1-0-0-A', 'autos')}>
						Comprar plan de Autos
					</Link>
					<Link to="/cobertura" className="button ctr" onClick={() => setPlan('0-0-1-A', 'hogar')}>
						Comprar plan de Hogar
					</Link>
				</div>
				<div className="cobertura-necesitas-content">
					<img src={falabellaLogoWhite} alt="Logo falabella" />
				</div>
			</div>
			<NuestrosClientes data={nuestrosClienteJson} />
			<Servicios id="servicios" />
		</div>
	);
}
