import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import { FunnelData } from '../interfaces/FunnelInterface';

const resetData = {
	step1: {
		cobertura: {
			auto: 1,
			motora: 0,
			hogar: 0,
		},
		pago: 'anual',
		plan: '',
		productType: 'autos',
	},
	step2: {
		nombre: '',
		apellido: '',
		telefono: '',
		email: '',
		direccion: '',
		ciudad: '',
		estado: '',
		codigoPostal: '',
	},
	step3: {
		nombreTarjeta: '',
		numeroTarjeta: '',
		cardMonth: '',
		cardYear: '',
		codigoTarjeta: '',
		discountAmount: 0,
	},
	total: {
		anualPrice: 0.0,
		anualPerc: 0.0,
		mensualPrice: 0.0,
		mensualPerc: 0.0,
		autoAnualPrice: 0.0,
		motoAnualPrice: 0.0,
		homeAnualPrice: 0.0,
		autoMensualPrice: 0.0,
		motoMensualPrice: 0.0,
		homeMensualPrice: 0.0,
	},
	subscriptionData: {
		mongoId: '',
		stripeId: '',
		leadMongoId: '',
	},
	perfilData: [
		{
			key: 0,
			h3: '',
			title: '',
			component: '',
			display: false,
		},
	],
	branch: '',
	utm: {
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
		utm_id: '',
	},
};

const initialState = {
	funnel: {
		step1: {
			cobertura: {
				auto: 1,
				motora: 0,
				hogar: 0,
			},
			pago: 'anual',
			plan: '1-0-0-A',
			productType: 'autos',
		},
		step2: {
			nombre: '',
			apellido: '',
			telefono: '',
			email: '',
			direccion: '',
			estado: '',
			canton: '',
			codigoPostal: '',
			numeroCedula: '',
			tipoCedula: '',
			distrito: '',
		},
		step3: {
			nombreTarjeta: '',
			numeroTarjeta: '',
			cardMonth: '',
			cardYear: '',
			codigoTarjeta: '',
			coupon: '',
			discount: '',
			hasDiscount: false,
			couponId: '',
			promotionCodeId: '',
			isFacebookLanding: false,
			discountAmount: 0,
			validCoupon: false,
			couponIsHidden: false,
		},
		total: {
			anualPrice: 0.0,
			anualPerc: 0.0,
			mensualPrice: 0.0,
			mensualPerc: 0.0,
			autoAnualPrice: 0.0,
			motoAnualPrice: 0.0,
			homeAnualPrice: 0.0,
			autoMensualPrice: 0.0,
			motoMensualPrice: 0.0,
			homeMensualPrice: 0.0,
		},
		subscriptionData: {
			amount: 0,
			mongoId: '',
			stripeId: '',
			leadMongoId: '',
			autoSelected: '',
			autoColor1: '',
			autoColor2: '',
			autoColor3: '',
			autoColor4: '',
			autoColor5: '',
			autoMake1: '',
			autoMake2: '',
			autoMake3: '',
			autoMake4: '',
			autoMake5: '',
			autoModel1: '',
			autoModel2: '',
			autoModel3: '',
			autoModel4: '',
			autoModel5: '',
			autoPlate1: '',
			autoPlate2: '',
			autoPlate3: '',
			autoPlate4: '',
			autoPlate5: '',
			autoYear1: '',
			autoYear2: '',
			autoYear3: '',
			autoYear4: '',
			autoYear5: '',
			hogarSelected: '',
			homeAddress11: '',
			homeAddress12: '',
			homeAddress13: '',
			homeAddress14: '',
			homeAddress15: '',
			homeAddress21: '',
			homeAddress22: '',
			homeAddress23: '',
			homeAddress24: '',
			homeAddress25: '',
			homeBanos1: '',
			homeBanos2: '',
			homeBanos3: '',
			homeBanos4: '',
			homeBanos5: '',
			homeCity1: '',
			homeCity2: '',
			homeCity3: '',
			homeCity4: '',
			homeCity5: '',
			homeCountry1: '',
			homeCountry2: '',
			homeCountry3: '',
			homeCountry4: '',
			homeCountry5: '',
			homeStatus2: '',
			homeStatus3: '',
			homeStatus4: '',
			homeStatus5: '',
			homeRooms1: '',
			homeRooms2: '',
			homeRooms3: '',
			homeRooms4: '',
			homeRooms5: '',
			homeType1: '',
			homeType2: '',
			homeType3: '',
			homeType4: '',
			homeType5: '',
			homeZipCode1: '',
			homeZipCode2: '',
			homeZipCode3: '',
			homeZipCode4: '',
			homeZipCode5: '',
			motoraSelected: '',
			motoraColor1: '',
			motoraColor2: '',
			motoraColor3: '',
			motoraColor4: '',
			motoraColor5: '',
			motoraMake1: '',
			motoraMake2: '',
			motoraMake3: '',
			motoraMake4: '',
			motoraMake5: '',
			motoraModel1: '',
			motoraModel2: '',
			motoraModel3: '',
			motoraModel4: '',
			motoraModel5: '',
			motoraPlate1: '',
			motoraPlate2: '',
			motoraPlate3: '',
			motoraPlate4: '',
			motoraPlate5: '',
			motoraYear1: '',
			motoraYear2: '',
			motoraYear3: '',
			motoraYear4: '',
			motoraYear5: '',
			agentName: '',
			b2b2c: 'Fulcro',
			platform: 'Fulcro Website',
		},
		perfilData: [
			{
				key: 0,
				h3: '',
				title: '',
				section: '',
				display: false,
			},
		],
		provincies: [],
		cantones: [],
		distritos: [],
		preSale: false,
		branch: '',
		utm: {
			utm_source: '',
			utm_medium: '',
			utm_campaign: '',
			utm_id: '',
		},
	},
	setFunnel: (data: any) => {},
	reset: (data: any) => {},
};

export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(AppReducer, initialState);

	const setFunnel = (funnel: FunnelData) => {
		dispatch({
			type: 'SET_FUNNEL',
			data: funnel,
		});
	};

	const reset = () => {
		dispatch({
			type: 'RESET',
			data: resetData,
		});
	};

	return (
		<GlobalContext.Provider
			value={{
				funnel: state.funnel,
				setFunnel,
				reset,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};
