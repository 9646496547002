import React, { useContext, useEffect, useState } from 'react';
import './perfil-hogar.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';

import FunnelHeader from 'components/funnel/header/header';
import { makeStyles } from '@material-ui/core';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import { AppContext } from 'helpers/Context';
import TextInput from 'components/inputs/textfield/textfield';
import DropDownInput from 'components/inputs/dropdown/dropdown';
import { GlobalContext } from 'context/GlobalState';
import { useFormik } from 'formik';
import LoadingDialog from 'helpers/LoadingDialog/loadingDialog';
import { getBranch } from 'helpers/Convertions';
import { hogarCantidades, tipoPropiedad } from 'helpers/Peril';
import { updateItems } from 'services/PerfilService';
import Toaster from 'helpers/Toaster';
import FunnelResumen from '../resumen/resumen';
import Modal from 'components/modal/modal';
import { useTranslation } from 'react-i18next';
import { FunnelData } from 'interfaces/FunnelInterface';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(1),
			width: '100%',
		},
	},
	textField: {
		marginTop: theme.spacing(0),
	},
}));

interface IFormInputs {
	address: string;
	city: string;
	zipCode: string;
	state: string;
	tipoPropiedad: string;
	habitaciones: string;
	banos: string;
}
export default function FunnelPerfilHogar(props: any) {
	const { t } = useTranslation();
	const { setShowStatus, branch } = useContext(AppContext);
	const { funnel } = useContext(GlobalContext);
	const [isLoading, setIsLoading] = useState(false);
	const pais = getBranch(branch);
	const [resumenType, setResumenType] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [funnelData] = useState<FunnelData>(funnel);

	const initialValues: IFormInputs = {
		address:
			props.countHogar === 1
				? funnel.subscriptionData.homeAddress11
				: props.countHogar === 2
				? funnel.subscriptionData.homeAddress12
				: props.countHogar === 3
				? funnel.subscriptionData.homeAddress13
				: props.countHogar === 4
				? funnel.subscriptionData.homeAddress14
				: funnel.subscriptionData.homeAddress15,
		city:
			props.countHogar === 1
				? funnel.subscriptionData.homeCity1
				: props.countHogar === 2
				? funnel.subscriptionData.homeCity2
				: props.countHogar === 3
				? funnel.subscriptionData.homeCity3
				: props.countHogar === 4
				? funnel.subscriptionData.homeCity4
				: funnel.subscriptionData.homeCity5,
		zipCode:
			props.countHogar === 1
				? funnel.subscriptionData.homeZipCode1
				: props.countHogar === 2
				? funnel.subscriptionData.homeZipCode2
				: props.countHogar === 3
				? funnel.subscriptionData.homeZipCode3
				: props.countHogar === 4
				? funnel.subscriptionData.homeZipCode4
				: funnel.subscriptionData.homeZipCode5,
		state:
			props.countHogar === 1
				? funnel.subscriptionData.homeCountry1
				: props.countHogar === 2
				? funnel.subscriptionData.homeCountry2
				: props.countHogar === 3
				? funnel.subscriptionData.homeCountry3
				: props.countHogar === 4
				? funnel.subscriptionData.homeCountry4
				: funnel.subscriptionData.homeCountry5,
		tipoPropiedad:
			props.countHogar === 1
				? funnel.subscriptionData.homeType1
				: props.countHogar === 2
				? funnel.subscriptionData.homeType2
				: props.countHogar === 3
				? funnel.subscriptionData.homeType3
				: props.countHogar === 4
				? funnel.subscriptionData.homeType4
				: funnel.subscriptionData.homeType5,
		habitaciones:
			props.countHogar === 1
				? funnel.subscriptionData.homeRooms1
				: props.countHogar === 2
				? funnel.subscriptionData.homeRooms2
				: props.countHogar === 3
				? funnel.subscriptionData.homeRooms3
				: props.countHogar === 4
				? funnel.subscriptionData.homeRooms4
				: funnel.subscriptionData.homeRooms5,
		banos:
			props.countHogar === 1
				? funnel.subscriptionData.homeBanos1
				: props.countHogar === 2
				? funnel.subscriptionData.homeBanos2
				: props.countHogar === 3
				? funnel.subscriptionData.homeBanos3
				: props.countHogar === 4
				? funnel.subscriptionData.homeBanos4
				: funnel.subscriptionData.homeBanos5,
	};

	const validate = (values: any) => {
		const errors: any = {};
		if (!values.address) {
			errors.address = 'Campo Requerido';
		}
		if (!values.city) {
			errors.city = 'Campo Requerido';
		}
		if (!values.zipCode) {
			errors.zipCode = 'Campo Requerido';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		validate,
		onSubmit: async (values) => {
			const key = props.countHogar;
			const section = props.component.section;
			const data = {
				_id: funnel.subscriptionData.mongoId,
				fields: {
					[`${section}Address${key}1`]: formik.values.address,
					[`${section}ZipCode${key}`]: formik.values.zipCode,
					[`${section}City${key}`]: formik.values.city,
					[`${section}Type${key}`]: formik.values.tipoPropiedad,
					[`${section}Rooms${key}`]: formik.values.habitaciones,
					[`${section}Banos${key}`]: formik.values.banos,
				},
			};
			setIsLoading(true);
			const updateItem = await updateItems(data);
			if (!updateItem.status) {
				Toaster.error('Error');
				setIsLoading(false);
				return;
			}

			props.handleClick(formik.values, props.component);
		},
	});

	useEffect(() => {
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
	}, [setShowStatus]);

	const classes = useStyles();
	const showModal = (type: string) => {
		setResumenType(type);
		setModalOpen(true);
	};
	const hideModal = () => {
		setModalOpen(false);
	};
	return (
		<div className="App">
			<LoadingDialog isLoading={isLoading}></LoadingDialog>
			<div className="App-header">
				<FunnelFooterMobile
					className="mobile"
					section="Completar Perfil"
					linkTo="finalizar"
					totalScreen={props.totalScreen}
					currentScreen={props.currentScreen}
					showChevron={props.chevron}
				/>
				<FunnelHeader progress="4" />
			</div>
			<div className="funnel__main">
				<div className="funnel__main__inner">
					<Modal show={modalOpen} handleClose={hideModal}>
						<p>{t(`resumen.${resumenType}.resumenTitulo`)}</p>
						{t(`resumen.${resumenType}.masDetalles`)}
					</Modal>
					<form onSubmit={formik.handleSubmit} className={classes.root} noValidate autoComplete="off">
						<div className="perfil-vehiculo-content funnel__cols">
							<div className="funnel__col funnel__col-1">
								<div className="perfil-hogar-detalles-container">
									<h1 className="h1">Agrega tu hogar </h1>
									<p>
										Para finalizar agregue la información <br /> del hogar que desea cubrir.
									</p>
									<div className="perfil-form-content">
										<TextInput
											name="address"
											className={`full-width`}
											label="Dirección"
											onChange={formik.handleChange}
											value={formik.values.address}
											errorinput={formik.errors.address ? 1 : 0}
											errorlabel={formik.errors.address}
										></TextInput>

										<TextInput
											name="city"
											className="full-width"
											label="Ciudad"
											onChange={formik.handleChange}
											value={formik.values.city}
											errorinput={formik.errors.city ? 1 : 0}
											errorlabel={formik.errors.city}
										></TextInput>
										<TextInput
											name="state"
											className="full-width"
											label="EST"
											value={pais}
											onChange={formik.handleChange}
											disabled
										></TextInput>

										<TextInput
											name="zipCode"
											className="full-width"
											id="outlined-basic"
											label="Código postal"
											onChange={formik.handleChange}
											value={formik.values.zipCode}
											errorinput={formik.errors.zipCode ? 1 : 0}
											errorlabel={formik.errors.zipCode}
										/>
										<DropDownInput
											name="tipoPropiedad"
											value={formik.values.tipoPropiedad}
											data={tipoPropiedad}
											onChange={formik.handleChange}
											className={`${classes.textField} full-width`}
											label="Tipo de Propiedad"
										></DropDownInput>

										<DropDownInput
											name="habitaciones"
											value={formik.values.habitaciones}
											data={hogarCantidades()}
											onChange={formik.handleChange}
											className={`${classes.textField} full-width`}
											label="Habitaciones"
										></DropDownInput>

										<DropDownInput
											name="banos"
											value={formik.values.banos}
											data={hogarCantidades()}
											onChange={formik.handleChange}
											className={`${classes.textField} full-width`}
											label="Baños"
										></DropDownInput>
									</div>
								</div>
								<div className="perfil-vehiculo-legal-content">
									<p>
										Una vez hayas añadido la información a tu perfil, tu suscripción quedará activa y lista para su uso
										luego de 72 horas.
									</p>
									<p>
										Si en el futuro necesitas cambiar o actualizar tu información, contáctanos y te ayudamos a realizar
										los cambios.
									</p>
								</div>
							</div>
							<OrangeButton className="perfil-vehiculo-orange-btn full-width mobile">{props.btnLabel}</OrangeButton>
							<div className="perfil-vehiculo-direction-column funnel__col funnel__col-2 ctr">
								<div className="desktop">
									<FunnelResumen
										show="desktop"
										data={funnel}
										pago={
											funnelData.step1.pago === 'mensual' ? funnelData.total.mensualPrice : funnelData.total.anualPrice
										}
										price={
											funnelData.step1.pago === 'mensual'
												? funnelData.total.mensualPrice - funnelData.step3.discountAmount
												: funnelData.total.anualPrice - funnelData.step3.discountAmount
										}
										discount={funnelData.step3.discountAmount}
									/>
								</div>
							</div>
						</div>

						<FunnelFooterDesktop
							className="desktop"
							btnLabel={props.btnLabel}
							section="Completar Perfil"
							totalScreen={props.totalScreen}
							currentScreen={props.currentScreen}
							component={props.component}
							handleClick={formik.handleSubmit}
							showChevron={props.chevron}
						/>
					</form>
				</div>
			</div>
		</div>
	);
}
