import React, { ReactNode } from 'react';

import './container.scss';

interface IProps {
	children: ReactNode;
	className?: string;
}

const Container: React.FC<IProps> = ({ children, className }) => (
	<div className={`ct-container ${className}`}>{children}</div>
);

export default Container;
