import React from 'react';
import './funnel-footer-desktop.scss';
//import RightArrow from '../../../../images/svg/right-arrow.svg';
import Arrow from '../../../../images/svg/arrow.svg';
import OrangeButton from '../../../inputs/orange-button/orange-button';
import { Link, useHistory } from 'react-router-dom';

interface IProps {
	className?: string;
	section?: string;
	active?: string;
	btnLabel?: string;
	linkTo?: string;
	classNameBtn?: string;
	showChevron?: Boolean;
	handleClick?: any;
	component?: any;
	totalScreen?: number;
	currentScreen?: number;
	btnIsDisabled?: boolean;
}

const FunnelFooterDesktop: React.FC<IProps> = ({
	className,
	section,
	active,
	btnLabel,
	linkTo,
	classNameBtn,
	showChevron,
	handleClick,
	component,
	totalScreen,
	currentScreen,
	btnIsDisabled,
}) => {
	let history = useHistory();
	return (
		<div className="funnel-footer-desktop desktop">
			<div className="funnel-footer-desktop-container">
				<div className="funnel-footer-desktop-info-div">
					{/* TODO Make back link clickable */}
					{!showChevron && (
						<Link className="desktop funnel-footer-desktop-left-arrow" to="#" onClick={() => history.goBack()}>
							<img src={Arrow} className="arrow arrow--left" alt="" />
						</Link>
					)}

					{section === 'steps' ? (
						<ul className="funnel-footer-desktop-ul">
							<li
								className={active === 'cobertura' ? 'funnel-footer-desktop-active' : 'funnel-footer-desktop-disabled'}
							>
								PLANES
							</li>
							<li>
								<img className="arrow arrow--small" src={Arrow} alt="" />
							</li>
							<li className={active === 'info' ? 'funnel-footer-desktop-active' : 'funnel-footer-desktop-disabled'}>
								INFO
							</li>
							<li>
								<img className="arrow arrow--small" src={Arrow} alt="" />
							</li>
							<li className={active === 'pago' ? 'funnel-footer-desktop-active' : 'funnel-footer-desktop-disabled'}>
								PAGO
							</li>
						</ul>
					) : (
						<ul className="funnel-footer-desktop-ul">
							<li className="funnel-footer-desktop-active">{section}</li>
						</ul>
					)}
				</div>

				{section === 'Completar Perfil' && (
					<div className="funnel-footer-desktop-tracking">
						{currentScreen}
						<span className="tracking-divider">/</span>
						{totalScreen}
					</div>
				)}

				<div className="width100">
					<OrangeButton
						handleClick={() => handleClick({}, component)}
						className={`funnel-footer-desktop-continuar-btn desktop`}
						classNameBtn={classNameBtn}
						type="button"
					>
						{!btnLabel ? 'CONTINUAR' : btnLabel}
					</OrangeButton>
				</div>
			</div>
		</div>
	);
};

export default FunnelFooterDesktop;
