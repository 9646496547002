import React from 'react';

import './hero-products.scss';
interface Props {
	type: 'auto' | 'hogar';
}

const ServicesHeroProducts: React.FC<Props> = ({ type }) => {
	return (
		<div className={`service-hero-products-container`}>
			<div className={`service-hero-products-content ${type}`}>
				<div className="inner ct-container">
					<div></div>
					<div className="service-hero-bottom">
						<h1
							dangerouslySetInnerHTML={{
								__html: type === 'auto' ? 'Asistencia para autos' : 'Asistencia en tu <br /> hogar',
							}}
						></h1>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServicesHeroProducts;
