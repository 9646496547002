import React from 'react';
import './detail-plan.scss';

interface Pros {
	typeProduct?: string;
	list: {
		service: string;
		event: string;
		limit: string;
	}[];
}

const DetailPlan: React.FC<Pros> = ({ list, typeProduct }) => (
	<div className="detail__plan__container">
		<div className="detail__plan__col detail__plan__col__big">
			<div className="detail__plan__col__header">
				<div className="title">Servicios</div>
				<div className="subtitle">
					{typeProduct === 'autos' ? (
						<>
							desde
							<br className="mobile" /> Millas '0'
						</>
					) : (
						<br />
					)}
				</div>
			</div>
			<div className="detail__plan__col__content">
				{list.map((item, index) => (
					<div key={'service' + index}>{item.service}</div>
				))}
			</div>
		</div>
		<div className="detail__plan__col">
			<div className="detail__plan__col__header border">
				<div className="title">Cobertura</div>
				<div className="subtitle">
					por
					<br className="mobile" /> evento
				</div>
			</div>
			<div className="detail__plan__col__content border">
				{list.map((item, index) => (
					<div key={'limit' + index}>{item.limit}</div>
				))}
			</div>
		</div>
		<div className="detail__plan__col">
			<div className="detail__plan__col__header">
				<div className="title">Límite</div>
				<div className="subtitle">
					de <br className="mobile" />
					eventos
				</div>
			</div>
			<div className="detail__plan__col__content text--center">
				{list.map((item, index) => (
					<div key={'event' + index}>{item.event}</div>
				))}
			</div>
		</div>
	</div>
);

export default DetailPlan;
