import React, { ReactNode } from 'react';

import './radio-button.scss';

interface IProps {
	children?: ReactNode;
	className?: string;
	price?: string;
	label?: string;
	pricePerc?: number;
	checkedStatus?: boolean;
	handleClick?: any;
}

const RadioButton: React.FC<IProps> = ({
	children,
	className,
	label,
	price,
	pricePerc,
	checkedStatus,
	handleClick,
}) => (
	<div className={`radio-button ${className}`} onClick={handleClick}>
		<div className="radio">
			<div className={`radio-indicator ${className}`}></div>
			<div className="radio-label">{label}</div>
		</div>
		{price ? <div className="radio-qty ">${price}</div> : ''}
		{pricePerc ? (
			<div className="radio-discount">
				{pricePerc}% de descuento
			</div>
		) : (
			''
		)}
		{children}
	</div>
);

export default RadioButton;
