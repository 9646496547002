import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './header-desktop.scss';
import { getFunnelUrl } from '../../../helpers/FunnelRedirection';
import segurosFulcroLogo from '../../../images/svg/seguros-fulcro-logo.svg';
import closeBtn from '../../../images/svg/close-btn.svg';
import { useTranslation } from 'react-i18next';
import useHideOnScrolled from 'helpers/UseHideScroll';
import { NavHashLink } from 'react-router-hash-link';

export default function HeaderDesktop() {
	const hidden = useHideOnScrolled(50);
	const { t } = useTranslation();
	const linkTo = getFunnelUrl();
	const [isNavEmergencyVisible, setIsNavEmergencyVisible] = useState(false);
	const emergenciaClose = () => {
		setIsNavEmergencyVisible(false);
	};
	return (
		<div className="header__container__desktop desktop">
			{isNavEmergencyVisible && (
				<div className={`emergencia__desktop toast ${hidden ? 'on--scroll' : ''}`}>
					<div className="emergencia__desktop__title">¿Tienes una emergencia?</div>
					<div className="emergencia__desktop__links">
						<a href={t('phoneLink')} className="icon-btn phone">
							Chat
						</a>
						<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')} className="icon-btn chat">
							Chat
						</a>
						<div className="link close" onClick={emergenciaClose}>
							<img src={closeBtn} alt="close" />
						</div>
					</div>
				</div>
			)}
			<header className="desktop">
				<Link
					to={{ pathname: 'https://fulcroinsurance.com/fulcro-assist/' }}
					target="_top"
					className="logo header__col left"
				>
					<img src={segurosFulcroLogo} alt="Connect Assistance" />
				</Link>
				<div className="header__col right">
					{/* <Link className="button__link" to={'/servicios-shared/auto'}>
						Asistencia para autos
					</Link>
					<Link className="button__link" to={'/servicios-shared/hogar'}>
						Asistencia en tu hogar
					</Link>
					<NavHashLink className="button" to="/#servicios" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
						Comprar plan
					</NavHashLink> */}
				</div>
			</header>
		</div>
	);
}
