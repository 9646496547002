import React from 'react';

import './items.scss';

import auto from '../../../images/home/servicios/car-icon.png';
import moto from '../../../images/home/servicios/moto-icon.png';
import home from '../../../images/home/servicios/home-icon.png';
import rightArrow from '../../../images/svg/right-arrow.svg';
import { Link } from 'react-router-dom';
import { getVehicleFormCountry } from '../../../helpers/Convertions';

interface Props {
	linkTo: string;
	branch: string;
}

const ServiceItems: React.FC<Props> = ({ linkTo, branch }) => {
	return (
		<div className="container">
			<div className="servicios-intro">
				<div className="servicios-intro__top">
					<h1 className="orange-title">Servicios</h1>
					<h2>
						Líderes en asistencia en<br></br>carretera y en el hogar.
					</h2>

					<h4>Conoce más sobre nuestros planes</h4>
				</div>
				<div className="servicios-intro__bottom">
					<div className="products">
						<Link to={branch === 'pr' ? '/servicios-shared/auto' : linkTo}>
							<img className="icon" src={auto} alt=""></img>
							<h4>Asistencia para auto</h4>
							<img className="arrow" src={rightArrow} alt="" height="14"></img>
						</Link>
						<Link to={'/servicios-shared/hogar'}>
							<img className="icon" src={home} alt=""></img>
							<h4>Asistencia para hogar</h4>
							<img className="arrow" src={rightArrow} alt="" height="14"></img>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceItems;
