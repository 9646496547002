import { useState, useEffect } from 'react';

const useHideOnScrolled = (topValue = 790) => {
	const [hidden, setHidden] = useState(false);

	const handleScroll = () => {
		const top = window.pageYOffset || document.documentElement.scrollTop;
		setHidden(top > topValue);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	console.log('hidden', hidden);

	return hidden;
};

export default useHideOnScrolled;
