import React from 'react';

import './experiencia-connect.scss';
import Container from '../container/container';

interface Props {
	title: string;
	subTitle: string;
	reverse: boolean;
	img: string;
}

const ExperienciaConnect: React.FC<Props> = ({ title, subTitle, reverse, img }) => {
	return (
		<div className={`exp-content ${reverse ? 'exp-reverse' : ''}`}>
			<div>
				<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
				<p dangerouslySetInnerHTML={{ __html: subTitle }}></p>
			</div>
			<div className="img__container">
				<img className="exp-circle" src={img} alt="" />
			</div>
		</div>
	);
};

export default ExperienciaConnect;
