import React, { useState, useContext } from 'react';
import './resumen.scss';
import { FunnelData } from '../../../interfaces/FunnelInterface';
import { AppContext } from '../../../helpers/Context';
import Pricing from 'helpers/Pricing';
import DetailPlan from 'components/detail-plan/detail-plan';
import Incluye from 'components/Incluye/incluye';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalState';
import AutoData from 'data/auto.json';
import HogarData from 'data/hogar.json';
interface IProps {
	className?: string;
	show?: String;
	data: FunnelData;
	price?: number;
	pago?: number;
	modalClick?: any;
	template?: 'secondary';
	discount?: number;
}

const FunnelResumen: React.FC<IProps> = ({ className, show, data, price, pago, discount, template }) => {
	price = Number(price);
	pago = Number(pago);
	const { branch } = useContext(AppContext);
	const { funnel } = useContext(GlobalContext);
	const [funnelData] = useState<FunnelData>(funnel);
	const [handleShowDetail, setHandleShowDetail] = useState(false);
	const [isCheckboxStatus, setCheckboxStatus] = useState(false);
	const handleInputChange = (event: any) => {
		!isCheckboxStatus ? setCheckboxStatus(true) : setCheckboxStatus(false);
	};
	const showPlanDetail = () => {
		setHandleShowDetail((handleShowDetail) => !handleShowDetail);
		setCheckboxStatus((isCheckboxStatus) => !isCheckboxStatus);
	};

	return (
		<div>
			<div className="mobile">
				<div className="wrap-collabsible">
					{!handleShowDetail ? (
						<>
							<input
								id="collapsible"
								className="toggle"
								type="checkbox"
								onChange={handleInputChange}
								checked={isCheckboxStatus}
							/>
							<label htmlFor="collapsible" className={`lbl-toggle mas-info orange fadeIn ${template}`}>
								Ver detalles del plan
							</label>
							<div className="collapsible-content fadeIn">
								<div className="content-inner">
									<div className={`funnel-resumen-content `}>
										<div className="funnel-resumen-top-div">
											<div className="funnel-resumen-h4">
												{data?.step1.cobertura.auto ? `Asistencia vial para carro (${data?.step1.cobertura.auto})` : ``}
												{data?.step1.cobertura.hogar ? `Asistencia para hogar (${data?.step1.cobertura.hogar})` : ``}
											</div>
											<div className="funnel-resumen-pago">
												Total a pagar
												<span>${Pricing.formatPrice(price, branch, 2)}</span>
											</div>
											{discount ? (
												<>
													<div className="funnel-resumen-pago-subtotal">
														Subtotal <span>${Pricing.formatPrice(price as number, branch, 2)}</span>
													</div>
													<div className="funnel-resumen-pago-discount">
														Descuento ${Pricing.formatPrice(discount as number, branch, 2)}
													</div>
												</>
											) : (
												''
											)}
										</div>

										<Incluye
											template="primary"
											data={funnelData.step1.productType === 'autos' ? AutoData.listLeftPlan : HogarData.listLeftPlan}
										></Incluye>
										<Link onClick={showPlanDetail} to={'#'} className="funel-resumen-link-detail">
											Ver más detalles
										</Link>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="lbl-toggle close bold fadeIn" onClick={showPlanDetail}>
								Más detalles
							</div>
							<div className="funnel-resumen-content-detail fadeIn">
								<DetailPlan
									typeProduct={funnelData.step1.productType}
									list={funnelData.step1.productType === 'autos' ? AutoData.list : HogarData.list}
								></DetailPlan>
							</div>
						</>
					)}
				</div>
			</div>

			<div className="desktop">
				<div className="funnel-resumen-content">
					{!handleShowDetail ? (
						<>
							<div className="funnel-resumen-top-div">
								<div className="mas-info uppercase orange mobile">¿Qué incluye tu plan?</div>
								<div className="funnel-resumen-h4 margin-top-12">
									{data?.step1.cobertura.auto ? `Asistencia vial para carro (${data?.step1.cobertura.auto})` : ``}
									{data?.step1.cobertura.hogar ? `Asistencia para hogar (${data?.step1.cobertura.hogar})` : ``}
								</div>
								<div className="funnel-resumen-pago">
									Total a pagar <span>${Pricing.formatPrice(price, branch, 2)}</span>
								</div>
								{discount ? (
									<>
										<div className="funnel-resumen-pago-subtotal">
											Subtotal <span>${Pricing.formatPrice(pago as number, branch, 2)}</span>
										</div>
										<div className="funnel-resumen-pago-discount">
											Descuento ${Pricing.formatPrice(discount as number, branch, 2)}
										</div>
									</>
								) : (
									''
								)}
							</div>
							<Incluye
								template="primary"
								data={funnelData.step1.productType === 'autos' ? AutoData.listLeftPlan : HogarData.listLeftPlan}
							></Incluye>
							<Link onClick={showPlanDetail} to={'#'} className="funel-resumen-link-detail">
								Ver más detalles
							</Link>
						</>
					) : (
						<>
							<div className="lbl-toggle close bold fadeIn white" onClick={showPlanDetail}>
								Más detalles
							</div>
							<div className="funnel-resumen-content-detail fadeIn">
								<DetailPlan
									typeProduct={funnelData.step1.productType}
									list={funnelData.step1.productType === 'autos' ? AutoData.list : HogarData.list}
								></DetailPlan>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default FunnelResumen;
