import { utmData } from 'interfaces/FunnelInterface';
import * as constants from '../helpers/Constants';
import api from './Api';

const createLead = async (branch: string, utm: utmData) => {
	return await api.post('/sales/cSalesFunel', {
		branch: branch,
		utm: utm,
		accountId: constants.ACCOUNT_ID,
		accountName: 'Fulcro B2B2C',
		sourceType: 'Fulcro B2B2C',
		platform: 'Fulcro Website',
	});
};

const updateLead = async (data: any, utm: utmData) => {
	return await api.post('/sales/uSalesFunel', {
		_id: data._id,
		fields: data,
		utm: utm,
		sourceType: 'Fulcro B2B2C',
		platform: 'Fulcro Website',
	});
};

export { createLead, updateLead };
