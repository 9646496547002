import React, { useEffect, useContext } from 'react';

// Accordian Module
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import './faq.scss';

import Container from 'components/container/container';
import OrangeTitle from 'components/orange-title/orange-title';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { AppContext } from 'helpers/Context';
import { getVehicleFormCountry } from 'helpers/Convertions';
import { Helmet } from 'react-helmet';
interface Props {}

const FAQ: React.FC<Props> = () => {
	const { identify, setIdentifyStatus, branch } = useContext(AppContext);

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="App faq">
			<Helmet>
				<title>Preguntas Frecuentes - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="hero bg--light-gray-3 ctr tall">
				<div className="container narrow">
					<h1 className="h1__faq">Preguntas Frecuentes</h1>
					<OrangeTitle>Siempre aquí cuando nos necesites.</OrangeTitle>
					<p className="p__faq">Encuentra las contestaciones a tus preguntas más importantes.</p>
				</div>
			</div>
			<nav className="jump-nav">
				<Container>
					<div className="inner">
						<div className="jump-nav__label">Brinca a una seccíon:</div>
						<ul>
							<li>
								<NavHashLink to="#carretera" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
									Carretera
								</NavHashLink>
							</li>
							<li>
								<NavHashLink to="#hogar" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
									Hogar
								</NavHashLink>
							</li>
						</ul>
					</div>
				</Container>
			</nav>
			<div className="faq__group no-focus-outline" id="carretera">
				<Container>
					<OrangeTitle className="faq-pad">Asistencia en Carretera (suscripciones):</OrangeTitle>
					<Accordion allowZeroExpanded>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Tienen proveedores de servicio en toda la Isla?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Tenemos proveedores en todo Puerto Rico disponibles para ti.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Qué pasa si tuve un accidente fuera de horario laboral?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									En caso de remolque por accidente y fuera de horario laboral, le cubre un segundo servicio. No tiene
									que ser desde el lugar del accidente.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Puedo suscribir un vehículo que esté a nombre de otra persona?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Los vehículos no tienen que estar todos bajo el mismo nombre para poder suscribirlos. La suscripción
									debe de estar a nombre de una sola persona, pero el carro o motora mencionado en el contrato tiene
									servicio de asistencia en carretera no importa quién lo esté manejando.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Qué servicios ofrecen si se me explotó una goma?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Si su vehículo tiene una goma de repuesta, le podemos brindar el servicio de cambio de goma. En caso
									de no tener goma de repuesta por diseño del manufacturero, le podemos brindar servicio de grúa (sin
									límites de milla al tener la suscripción).
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Qué métodos de pago aceptan?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Puede realizar el pago de su suscripción utilizando tarjeta de Crédito o Débito (de Visa, Master Card,
									American Express o Discover). También, aceptamos ATH Móvil.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Los vehículos comerciales cualifican para el servicio de Asistencia en Carretera?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Para cualificar para nuestro servicio de Asistencia en Carretera, su vehículo debe de ser privado. Al
									momento, los vehículos comerciales no cualifican.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Tengo que tener la suscripción de Connect para poder recibir sus servicios?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									No necesitas tener la suscripción para poder recibir nuestros servicios. Nuestro producto “On Demand”
									te permite recibir nuestros servicios a un costo preferencial cuando lo necesites llamando al
									787-999-5050. Aceptamos las principales tarjetas de crédito/débito y ATH Móvil.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Puedo cancelar la suscripción en cualquier momento?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Sí. Tendrás derecho a la parte no devengada del dinero que hayas pagado, calculada a prorrata, sin
									ninguna deducción ni penalidad.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Qué distancia puedo recorrer con el servicio de remolque?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>El millaje es ilimitado. Esto aplica tanto para motoras como para carros.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Tengo que pagar algún deducible por incidente una vez me suscriba al servicio?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>No tienes que pagar deducibles una vez suscrito.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Cuál es el máximo de vehículos por suscripción?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Hay un máximo de 5 vehículos por suscripción. Este máximo se aplica tanto para carros como para
									motoras.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Dónde está localizado el centro de llamadas de Connect Assistance?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Nuestro centro de llamadas es el único centro de asistencia en carretera localizado en Puerto Rico. Es
									parte de nuestro compromiso con nuestra Isla.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿De qué año tiene que ser la motora para poder suscribirse al servicio?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>La motora debe de tener 15 años de antigüedad o menos.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿De qué año tiene que ser el carro para poder suscribirse al servicio?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>El carro debe tener 15 años de antigüedad o menos.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Cuál es el costo de Asistencia en Carretera para Carros?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									El servicio de Asistencia en Carretera para carros de Connect tiene un costo de $5.99 mensual o $55.00
									anual. Si eliges el pago anual de $55.00, los autos adicionales tienen un costo de $27.50 por unidad.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>
				</Container>
			</div>
			<div className="faq__group no-focus-outline" id="hogar">
				<Container>
					<h2>
						<OrangeTitle className="faq-pad">Asistencia en el Hogar (suscripciones):</OrangeTitle>
					</h2>
					<Accordion allowZeroExpanded>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿El servicio incluye los materiales que se utilizarán?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									El límite de cada servicio incluye la mano de obra, traslado del técnico y materiales. Si se excede
									del límite, tendrá que pagar la diferencia al proveedor al momento del servicio.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿El servicio me cubre la terraza y marquesina?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									El programa de Asistencia en tu Hogar cubre toda la propiedad registrada de la estructura original.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Tengo que llamar para renovar la suscripción?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>No. Para su conveniencia, la suscripción se renueva de forma automática.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Cuánto tardan en llegar a asistirme?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Depende del tipo de asistencia que necesite. Si es una emergencia se le enviará un proveedor de
									inmediato y si no es una emergencia se programará una visita en el día y hora que sea conveniente para
									usted.
								</p>
								<p>
									Nuestro tiempo promedio de respuesta es de 30 minutos en toda la isla. Siempre trabajamos para que sea
									menos.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Hay un límite de servicios?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									La cubierta de Asistencia en tu Hogar tiene un límite de $350 por evento. Hay un máximo de 4 eventos
									por tipo de servicio los cuales incluyen: Plomería, Electricidad, Cerrajería y Cristalería.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Tengo que pagar algún deducible por incidente una vez me suscriba al servicio?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>No tienes que pagar deducibles una vez suscrito.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Dónde está localizado el centro de llamadas de Connect Hogar?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>El centro de llamadas está localizado en San Juan, Puerto Rico.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Cómo puedo adquirir la suscripción de Asistencia en tu Hogar?
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Puedes adquirir tu membresía <Link to={'/servicios-shared/hogar'}>aquí mismo</Link> por nuestra página
									web o llamando al 787-999-5050. Usando la página web es más fácil y rápido.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__faq">
									¿Cuál es el costo de Asistencia en tu Hogar? - Hogar
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>El servicio de Asistencia de Connect Hogar tiene un costo de $9.50 mensual o $89.00 anual.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>
				</Container>
			</div>
			<div className="hero dark ctr">
				<div className="container narrow">
					<h1>
						<OrangeTitle className="ctr">Contacto</OrangeTitle>
					</h1>
					<h2>¿Toadavía tienes preguntas?</h2>
					<p>No te preocupes. Estamos aquí para asistir.</p>
					<Link to="contacto" className="button ctr">
						Contáctanos
					</Link>
				</div>
			</div>
		</div>
	);
};

export default FAQ;
