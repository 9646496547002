import React, { useContext, useState } from 'react';

import './servicios.scss';

import Container from '../../container/container';

import CarIcon from '../../../images/home/servicios/car-icon.png';
import HomeIcon from '../../../images/home/servicios/home-icon.png';
import { Link } from 'react-router-dom';

import falabellaLogoWhite from 'images/svg/fulcro-white.svg';
import { getFunnelUrl } from '../../../helpers/FunnelRedirection';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';

interface Props {
	id: string;
}

const Servicios: React.FC<Props> = ({ id }) => {
	const linkTo = getFunnelUrl();
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const setPlan = (productType) => {
		funnelData.step1.plan = productType === 'autos' ? '1-0-0-A' : '0-0-1-A';
		funnelData.step1.productType = productType;
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};
	return (
		<div className="servicios" id={id}>
			<h1 className="title-green ctr">Servicios</h1>
			<div className="servicios__inner">
				<div className="servicios__left ctr-mobile">
					<h1 className="h1">Tenemos planes para ti y para tu compañía</h1>
					<p>
						Ya sea que busques proteger tu hogar o una flota de vehículos, en Connect encontrarás planes personalizados
						que se adaptan perfectamente a tus necesidades. Nuestros servicios están diseñados para brindarte la
						tranquilidad y seguridad que mereces.
						<br />
						<br />
						En Connect, tenemos todo lo que necesitas para estar bien protegido!
					</p>
					<div className="servicios-logo-falabella">
						<img src={falabellaLogoWhite} alt="Logo falabella" />
					</div>
				</div>
				<div className="servicios__right">
					<Link to={linkTo} onClick={() => setPlan('autos')}>
						<div className="servicios__item auto margin">
							<div className="servicios__item__img">
								<img src={CarIcon} alt="Auto" />
							</div>
							<h4>Asistencia para autos</h4>
						</div>
					</Link>
					<Link to={linkTo} onClick={() => setPlan('hogar')}>
						<div className="servicios__item auto">
							<div className="servicios__item__img">
								<img src={HomeIcon} alt="Hogar" />
							</div>
							<h4>Asistencia en tu hogar</h4>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Servicios;
