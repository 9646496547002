import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import './productos-shared.scss';

import ServicesHeroProducts from 'components/services/hero-products/hero-products';
import Container from 'components/container/container';
import OrangeTitle from 'components/orange-title/orange-title';
import ServiceNuestrosClientes from 'components/services/nuestros-clientes/nuestros-clientes';

import AutoData from 'data/auto.json';
import HogarData from 'data/hogar.json';
import AutoImg from 'images/services/service-auto.png';
import HogarImg from 'images/services/service-hogar.png';
import pago from 'images/svg/pago.svg';
import ServiceSubscribete from 'components/services/subscribete/subscribete';
import useHideOnScrolled from 'helpers/UseHideScroll';
import { AppContext } from 'helpers/Context';
import { getFunnelUrl } from 'helpers/FunnelRedirection';
import { Helmet } from 'react-helmet';
import Pricing from 'helpers/Pricing';
import DetailPlan from 'components/detail-plan/detail-plan';
import falabellaLogoWhite from 'images/svg/fulcro-white-logo.svg';
import carroFulcro from 'images/svg/carroFulcro.svg';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';

export default function ProductosShared() {
	const { branch, identify, setIdentifyStatus } = useContext(AppContext);
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const [isCheckboxStatus, setCheckboxStatus] = useState(true);
	let params: { section: 'auto' | 'hogar' } = useParams();
	const handleInputChange = (event: any) => {
		!isCheckboxStatus ? setCheckboxStatus(true) : setCheckboxStatus(false);
	};
	useEffect(() => {
		setPlan(params.section);
	}, [params]);
	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let data: any = {};
	let heroImg = '';
	let pricing = 0;
	const linkTo = getFunnelUrl();
	const setPlan = (productType: string) => {
		funnelData.step1.plan = productType === 'auto' ? '1-0-0-A' : '0-0-1-A';
		funnelData.step1.productType = productType === 'auto' ? 'autos' : 'hogar';
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};
	const dataCountPricing = {
		auto: 1,
		moto: 1,
		home: 1,
		pago: 'mensual',
		branch: branch,
	};
	const allPricing = Pricing.calculate(dataCountPricing);
	switch (params.section) {
		case 'auto':
			data = AutoData;
			heroImg = AutoImg;
			pricing = allPricing.autoMensualPrice;
			break;

		case 'hogar':
			data = HogarData;
			heroImg = HogarImg;
			pricing = allPricing.homeMensualPrice;
			break;
	}

	const nuestrosClienteJson = {};

	const hidden = useHideOnScrolled();

	return (
		<div className="App">
			<Helmet>
				<title>Consigue tu plan hoy - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header ">
				<div style={{ paddingTop: '0.1px' }}>
					{hidden && (
						<div className="mobile">
							<div
								className={`productos-shared-header-sub-container productos-shared-header-sub-container-white productos-shared-header-sub-container-fixed`}
							>
								<div className="productos-shared-header-sub-left">
									<div className="productos-shared-header-sub-label">Consigue tu plan hoy</div>
								</div>
								<div className="productos-shared-header-sub-right">
									<Link to="/#servicios" className="btn" target="_top">
										<div className="btn">Cotizar y comprar</div>
									</Link>
								</div>
							</div>
						</div>
					)}

					<ServicesHeroProducts type={params.section} />
					<div>
						<div className="productos-shared-productos-buy-container">
							<ServiceSubscribete
								branch={branch}
								data={data}
								section={data.section}
								linkTo={linkTo}
								pricing={pricing}
							/>
						</div>
						<div className="productos-shared-section-4">
							<div className="productos-shared-section-4-container">
								<div className="img__container">
									<img src={pago} alt="" />
								</div>
								<div className="text__container">
									<h1 className="title-green">¿Qué incluye tu plan?</h1>
									<h1 className="h1 product__shared">
										Un plan que se <br />
										adapta a ti
									</h1>
									<p className="p__include__plan" dangerouslySetInnerHTML={{ __html: data.text }}></p>
								</div>
							</div>
							<div className="productos-shared-section-4-detail">
								<DetailPlan
									typeProduct={funnelData.step1.productType}
									list={funnelData.step1.productType === 'autos' ? data.list : HogarData.list}
								></DetailPlan>
							</div>
							<div className="productos-shared-falabella-logo">
								<img src={falabellaLogoWhite} alt="Logo falabella" />
							</div>
						</div>
						<div className="productos-shared">
							<Container>
								<div className="productos-shared-container">
									<div className="productos-shared-section-3">
										{funnelData.step1.productType === 'autos' && (
											<div className="carro__fulcro__container">
												<ul>
													<li>
														Sin límite <br className="mobile" /> de eventos <br className="mobile" />
														por año
													</li>
													<li>
														Sin límite <br className="mobile" />
														de millas para <br className="mobile" />
														Servicios de grúa{' '}
													</li>
												</ul>
												<img className="carro__fulcro" src={carroFulcro} alt="Carro Fulcro" />
											</div>
										)}
										<h1 className="title-green">¿Cómo funciona?</h1>
										<div>
											<h2 dangerouslySetInnerHTML={{ __html: data.h2_title }}></h2>
											<p>{data.h2_info}</p>
										</div>
										<div className="productos-shared-section-3-items">
											<div className="productos-shared-section-3-item">
												<div className="productos-shared-section-3-item-period">
													<div className="productos-shared-section-3-item-period-square"></div>
													<div className="productos-shared-section-3-item-period-line"></div>
												</div>
												<div className="productos-shared-section-3-item-info">
													<h3
														dangerouslySetInnerHTML={{
															__html: data.experienca_1_title,
														}}
													></h3>
													<p>{data.experienca_1_info}</p>
												</div>
											</div>
											<div className="productos-shared-section-3-item">
												<div className="productos-shared-section-3-item-period">
													<div className="productos-shared-section-3-item-period-square"></div>
													<div className="productos-shared-section-3-item-period-line"></div>
												</div>
												<div className="productos-shared-section-3-item-info">
													<h3
														dangerouslySetInnerHTML={{
															__html: data.experienca_2_title,
														}}
													></h3>
													<p>{data.experienca_2_info}</p>
												</div>
											</div>
											<div className="productos-shared-section-3-item">
												<div className="productos-shared-section-3-item-period">
													<div className="productos-shared-section-3-item-period-square"></div>
												</div>
												<div className="productos-shared-section-3-item-info">
													<h3
														dangerouslySetInnerHTML={{
															__html: data.experienca_3_title,
														}}
													></h3>
													<p>{data.experienca_3_info}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Container>
						</div>
					</div>
				</div>
				<ServiceNuestrosClientes template="secondary" data={nuestrosClienteJson} />
			</div>
		</div>
	);
}
