import React, { ReactNode } from 'react';
import './Popup-exist-porcess.scss';
type TypeState = 'warning' | 'error' | 'successful';
interface IPropsPopupExistPorcess {
	type: TypeState;
	title: string;
	message: string;
	labelButton?: string;
	labelButtonCancel?: string;
	closePopUp: () => void;
	exitProcess?: () => void;
	children?: ReactNode;
	iconIsHidden?: boolean;
	reverseCallToAction?: boolean;
	isLoading?: boolean;
}
export const PopupExistPorcess = (props: IPropsPopupExistPorcess) => {
	return (
		<div className="pop__up__overlay">
			<div className="pop__up__container">
				<div className={`popup__exist_porcess ${props.type}`}>
					<h5>{props.title}</h5>
					{props.children ? (
						props.children
					) : (
						<p className="popup__exist_porcess__text" dangerouslySetInnerHTML={{ __html: props.message }}></p>
					)}

					<div className="popup__exist_porcess__container__btns">
						<button className={`popup__exist_porcess__btn ${props.type} ghost`} onClick={props.exitProcess}>
							{props.labelButtonCancel}
						</button>
						<button
							className={`popup__exist_porcess__btn ${props.type}`}
							onClick={!props.isLoading ? props.closePopUp : () => ''}
						>
							{!props.isLoading ? props.labelButton : <div className="loader small"></div>}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
