import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import segurosFulcroLogo from 'images/svg/seguros-fulcro-logo.svg';
import powerBy from 'images/svg/powered-by.svg';
import logocompleto from 'images/svg/logo-completo.svg';
import './footer-logos.scss';

const FooterLogos: React.FC = () => (
	<div className="mobile">
		<div className="footer__logos__container">
			<div className="nav__bottom">
				<div className="logo text-center">
					<Link to={{ pathname: 'https://fulcroinsurance.com/fulcro-assist/' }} target="_top" className="logo">
						<img src={segurosFulcroLogo} alt="Fulcro/ Connect Assistance" />
					</Link>
				</div>
			</div>
		</div>
	</div>
);

export default FooterLogos;
