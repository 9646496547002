import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AutoData from 'data/auto.json';
import HogarData from 'data/hogar.json';
const ResumenInfo = (props: any) => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="funnel-resumen-bottom-div">
				<div className="mas-info">Incluye</div>
				<div className="funnel-resumen-h4 margin-top-12">{t(`resumen.${props.category}.resumenTitulo`)}</div>
				<ul>
					{(props.category === 'auto' ? AutoData.listLeftPlan : HogarData.listLeftPlan).map(function (
						d: any,
						index: number
					) {
						return <li key={index}>{d.li}</li>;
					})}
				</ul>

				<Link onClick={() => props.modalClick(props.category)} to="#" className="funel-resumen-link-detail small">
					Ver más detalles
				</Link>
			</div>
		</div>
	);
};

export default ResumenInfo;
