import React from 'react';
import { Link } from 'react-router-dom';
import './subscribete.scss';
import PricingHelpers from 'helpers/Pricing';
import Incluye from 'components/Incluye/incluye';
interface Props {
	section?: string;
	linkTo: string;
	pricing: number;
	branch: string;
	data: any;
}

const ServiceSubscribete: React.FC<Props> = ({ data, linkTo, pricing, branch }) => {
	return (
		<div className="productos-buy-container">
			<div className="productos-buy-container-padding top">
				<h3 className="productos-buy-container-h3">Consigue tu plan hoy</h3>
				<p className="productos-buy-container-info">Es fácil y toma solo minutos en completar.</p>
				<div className="productos-buy-container-line line"></div>
				<Incluye template="secondary" data={data.listLeftPlan}></Incluye>
				<div className="productos-buy-container-divider"></div>
				<div className="productos-buy-container-price">
					Planes desde <span>${PricingHelpers.formatPrice(pricing, branch, 2)}</span> /mes.
				</div>
				<Link to={linkTo} className="button ctr">
					Comprar plan
				</Link>
			</div>
		</div>
	);
};

export default ServiceSubscribete;
