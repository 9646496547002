import React, { ReactNode, useContext, useState } from 'react';
import './header.scss';

import CloseBtn from '../../../images/svg/close-btn.svg';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalState';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { PopupExistPorcess } from 'components/Popup-exist-porcess/Popup-exist-porcess';

const MySwal = withReactContent(Swal);

interface IProps {
	children?: ReactNode;
	className?: string;
	price?: string;
	progress?: string;
}

const FunnelHeader: React.FC<IProps> = ({ className, progress }) => {
	const { reset } = useContext(GlobalContext);
	const [isShowModal, setIsShowModal] = useState<boolean>(false);
	const closeBtnClick = () => {
		reset('');
	};

	let history = useHistory();
	const onCloseFunnel = () => {
		if (Number(progress) < 4) {
			setIsShowModal((isShowModal) => !isShowModal);
		} else {
			history.push('/');
		}
	};
	const closeFunnel = () => {
		setIsShowModal(false);
		history.push('/');
	};
	return (
		<div>
			<div className="funnel-header-content desktop">
				<div className="funnel-header-content-left">
					<Link to={{ pathname: 'https://fulcroinsurance.com/fulcro-assist/' }} target="_top">
						<span className="funnel-header-content-left-logo" />
					</Link>
				</div>
				<div className="funnel-header-content-right">
					<Link onClick={onCloseFunnel} to="#">
						<img className="funnel-header-content-right-close-btn" src={CloseBtn} onClick={closeBtnClick} alt="" />
					</Link>
				</div>
			</div>
			<div className={`funnel-header-container ${className}`}>
				<div className={`funnel-header-progress funnel-header-progress-${progress}`}></div>
			</div>
			{isShowModal && (
				<PopupExistPorcess
					type="successful"
					title="¿Realmente desea salir?"
					message="La suscripción no se activa hasta que los datos estén completos"
					labelButton="Continuar"
					labelButtonCancel="Salir"
					closePopUp={onCloseFunnel}
					exitProcess={closeFunnel}
					reverseCallToAction={true}
				></PopupExistPorcess>
			)}
		</div>
	);
};

export default FunnelHeader;
