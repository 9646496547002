import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './pago.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';
import FunnelHeader from 'components/funnel/header/header';
import FunnelResumen from '../resumen/resumen';
import RadioButton from 'components/inputs/radio-button/radio-button';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import { AppContext } from 'helpers/Context';
import TextInput from 'components/inputs/textfield/textfield';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData, TotalPrice, SubscriptionData } from 'interfaces/FunnelInterface';
import { useFormik } from 'formik';
import LoadingDialog from 'helpers/LoadingDialog/loadingDialog';
import Pricing from 'helpers/Pricing';
import { processPayment } from 'services/PagoService';
import { updateLead } from 'services/LeadService';
import Toaster from 'helpers/Toaster';
import GoogleTagManager from 'helpers/GoogleTagManager';
import { getCupon } from 'services/SubscriptionService';
import info from 'images/svg/tooltip.svg';
import Klaviyo from 'helpers/Klaviyo';
import { loadStripe } from '@stripe/stripe-js';
import * as constants from 'helpers/Constants';
import Segment from 'helpers/Segment';
import Modal from 'components/modal/modal';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'react-google-invisible-recaptcha';
import { v4 as uuidv4 } from 'uuid';
import FooterLogos from 'components/footer-logos/footer-logos';
import FunnelResumenBundle from 'components/resumen-slider/resumen-slider';

interface IFormInputs {
	nombreTarjeta: string;
	checkedA: Boolean;
	isSend: Boolean;
	cupon: string;
}

export default function FunnelPago() {
	const history = useHistory();
	const { setShowStatus, branch } = useContext(AppContext);
	const { funnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const [autoCount] = useState(funnelData.step1.cobertura.auto);
	const [motoCount] = useState(funnelData.step1.cobertura.motora);
	const [homeCount] = useState(funnelData.step1.cobertura.hogar);
	const [total, setTotal] = useState<TotalPrice>(funnelData.total);
	const [pago, setPago] = useState(funnelData.step1.pago);
	const [isLoading, setIsLoading] = useState(false);
	const [isSend, setSend] = useState(false);
	const googleTag = GoogleTagManager;
	const [discount, setDiscount] = useState(funnelData.step3.discount);
	const [hasDiscount, setHasDiscount] = useState(funnelData.step3.hasDiscount);
	const [validCoupon, setValidCoupon] = useState(funnelData.step3.validCoupon);
	const [idCoupon, setIdCoupon] = useState(funnelData.step3.couponId);
	const [idPromotionCode, setIdPromotionCode] = useState(funnelData.step3.promotionCodeId);
	const [priceWithDiscount, setTotalWithDiscount] = useState<any | null>(null);
	const [errorCoupon, setErrorCoupon] = useState(false);
	const [focusCoupon, setFocusButton] = useState(false);
	const [priceSaved, setPriceSaved] = useState<any | null>(null);
	const [errorInput, setErrorInput] = useState(false);
	const [isFacebookLanding, setIsFacebookLanding] = useState(false);
	const [stripeLoad, setStripeLoad] = useState<any>(null);
	const [cardElement, setCardElement] = useState<any>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [showLockModal, setshowLockModal] = useState(false);
	const [resumenType, setResumenType] = useState('');
	const [coupon, setCoupon] = useState<any>(null);
	const [captcha, setCapcha] = useState<any | null>(null);

	const { t } = useTranslation();

	const showModal = (type: string) => {
		setResumenType(type);
		setModalOpen(true);
	};

	const hideModal = () => {
		setModalOpen(false);
	};

	const getKeyStripe = () => {
		return (
			{
				pr: constants.pkStripe,
				cr: constants.pkStripeCr,
				co: constants.pkStripeCr,
			}[branch] || 'pr'
		);
	};

	useEffect(() => {
		const fetchTags = async () => {
			googleTag.shoppingCardStep2(funnel);
			const anonymousIdSegment = uuidv4();
			window.analytics.setAnonymousId(anonymousIdSegment);
			Segment.identifyUser(anonymousIdSegment, funnel);
		};
		fetchTags();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funnel]);

	useEffect(() => {
		Klaviyo.trackProfileActivityCheckout(funnel);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let host = window.location.host;
		let parts = host.split('.');
		if (parts[0] === 'fb') {
			setIsFacebookLanding(true);
		} else {
			setIsFacebookLanding(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (funnel.step3.hasDiscount) {
			formik.values.cupon = funnel.step3.coupon;
			handleCupon(funnel.step3.coupon);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funnel]);

	const initialValues: IFormInputs = {
		nombreTarjeta: '',
		checkedA: false,
		isSend: false,
		cupon: '',
	};

	const validate = (values: any) => {
		const errors: any = {};

		if (!values.nombreTarjeta) {
			errors.nombreTarjeta = 'Campo Requerido';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		validate,
		onSubmit: async (values) => {
			setSend(true);

			if (funnelData.step2.email === '' || funnelData.step2.nombre === '') {
				Toaster.error('Oops, parece que tu información no fue ingresada. Por favor intente de nuevo.');
				history.push('/cobertura');
				return;
			}

			const data = {
				nombreTarjeta: formik.values.nombreTarjeta,
				coupon: formik.values.cupon,
				discount: discount,
				hasDiscount: hasDiscount,
				couponId: idCoupon,
				promotionCodeId: idPromotionCode,
				isFacebookLanding: isFacebookLanding,
				discountAmount: priceSaved,
				validCoupon: validCoupon,
			};
			setIsLoading(true);
			const tokenCaptcha = await validateCaptcha();

			const token = await stripeLoad.createToken(cardElement);
			setSend(true);
			funnelData.step3 = data;
			funnelData.branch = branch;
			setFunnelData(funnelData);

			try {
				const customerWithPlan = await processPayment(funnelData, token, tokenCaptcha);

				if (customerWithPlan.data.data && !customerWithPlan.data.data.status) {
					setSend(false);
					Toaster.error(customerWithPlan.data.data.msg);
					setIsLoading(false);
					return;
				}
				if (!customerWithPlan.status) {
					setSend(false);
					Toaster.error(customerWithPlan.msg);
					setIsLoading(false);
					return;
				}

				setSend(true);

				const { mongoSubId, subscriptionIdStripe: stripeId, amount } = customerWithPlan.data.data;

				const subData: SubscriptionData = {
					...funnelData.subscriptionData,
					mongoId: mongoSubId,
					stripeId: stripeId,
				};
				funnelData.subscriptionData = subData;
				setFunnelData(funnelData);
				setIsLoading(false);

				const updateData = {
					_id: funnelData.subscriptionData.leadMongoId,
					branch: branch.toUpperCase(),
					status: 'sold',
				};
				updateLead(updateData, funnel.utm);
				googleTag.purchase(funnelData, amount);

				Klaviyo.trackProfileActivityPlacedOrder(funnelData, amount);

				window.analytics.track('Payment Info Entered', {
					checkout_id: funnelData.subscriptionData.leadMongoId,
					order_id: funnelData.subscriptionData.mongoId,
					payment_method: 'Credit Card',
				});

				const totalToAnalytics =
					priceWithDiscount !== '' && discount !== ''
						? priceWithDiscount
						: pago === 'mensual'
						? total.mensualPrice.toFixed(2)
						: total.anualPrice.toFixed(2);
				const totalUnit = getTotal();

				window.analytics.track('Order Completed', {
					checkout_id: funnelData.subscriptionData.leadMongoId,
					order_id: funnelData.subscriptionData.mongoId,
					affiliation: funnelData.step1.plan,
					total: totalToAnalytics,
					subtotal: totalToAnalytics,

					discount: priceSaved !== '' && priceSaved > 0 && discount !== '' ? priceSaved : 0,
					coupon: coupon,
					currency: 'USD',
					products: [
						{
							product_id: funnel.step1.plan,
							name: funnelData.step1.plan,
							price: totalUnit,
							currency: 'usd',
							value: totalUnit,
							quantity: 1,
						},
					],
				});
				if (funnelData.preSale) {
					history.push('/finalizar');
				} else {
					history.push('/perfil/' + funnelData.subscriptionData.mongoId + '/0');
				}
			} catch (error) {
				if ((error as any)?.response?.data?.error === 'Too many requests') {
					setshowLockModal(true);
				} else {
					Toaster.error('Ocurrio un error, intentalo mas tarde');
				}
			} finally {
				setIsLoading(false);
			}
		},
	});

	const validateCaptcha = async () => {
		try {
			await captcha.execute();
			const token = captcha.getResponse();
			return token;
		} catch (error) {
			Toaster.error('Ocurrio un error, intentalo mas tarde');
		}
	};

	const calculate = () => {
		const data = {
			auto: autoCount,
			moto: motoCount,
			home: homeCount,
			pago: pago,
			branch: branch,
		};
		funnelData.step1.pago = pago;
		funnelData.step1.plan = autoCount + '-' + motoCount + '-' + homeCount + '-' + pago.charAt(0).toUpperCase();

		setFunnelData(funnelData);
		setTotal(Pricing.calculate(data));
	};

	useEffect(() => {
		setShowStatus(false);
		if (pago) {
			calculate();
		}
		return () => {
			setShowStatus(true);
		};
		// eslint-disable-next-line
	}, [setShowStatus, pago, funnelData]);

	const handleOnChange = (strCoupon: string) => {
		funnelData.step3.validCoupon = false;
		funnelData.step3.hasDiscount = false;
		funnelData.step3.coupon = '';
		funnelData.step3.discountAmount = 0;
		funnelData.step3.couponId = '';
		setTotalWithDiscount('');
		setDiscount('0');
		setPriceSaved(0);
		setHasDiscount(false);
		setIdCoupon('');
		setIdPromotionCode('');
		setFunnelData(funnelData);
		handleFocus(strCoupon);
	};
	const handleFocus = (strCoupon: string) => {
		formik.setFieldValue('cupon', strCoupon);
		if (strCoupon.length > 0) {
			setFocusButton(true);
		} else {
			setFocusButton(false);
		}
	};

	const handleChangePeriodPayment = (mensualAnual: string) => {
		setPago(mensualAnual);

		if (mensualAnual === 'mensual') {
			funnelData.step1.pago = 'mensual';
			funnelData.step1.plan = autoCount + '-' + motoCount + '-' + homeCount + '-M';
			setFunnelData(funnelData);
			setFunnelData(funnelData);
			handleOnChange(formik.values.cupon);
			handleCupon(formik.values.cupon);
		} else {
			funnelData.step1.pago = 'anual';
			funnelData.step1.plan = autoCount + '-' + motoCount + '-' + homeCount + '-A';
			setFunnelData(funnelData);
			handleOnChange(formik.values.cupon);
			handleCupon(formik.values.cupon);
		}
	};

	const handleCupon = async (value: any) => {
		if (value.length > 4) {
			setIsLoading(true);
			if (funnelData.step1.pago === 'mensual') {
				const restrictedMensual = constants.restrictedCouponsMensual?.split(',') || [];
				const hasRestrictedCouponMensual = restrictedMensual.indexOf(value) > -1;

				if (hasRestrictedCouponMensual) {
					funnelData.step3.validCoupon = false;
					setFunnelData(funnelData);
					Toaster.error('El código ingresado es inválido');
					setIsLoading(false);
					setErrorCoupon(true);
					setErrorInput(true);
					setTotalWithDiscount('');
					setPriceSaved(0);
					setFocusButton(false);
					setValidCoupon(false);
					return;
				}
			}

			if (funnelData.step1.pago === 'anual') {
				const restrictedAnual = constants.restrictedCouponsAnual?.split(',') || [];
				const hasRestrictedCouponAnual = restrictedAnual.indexOf(value) > -1;

				if (hasRestrictedCouponAnual) {
					funnelData.step3.validCoupon = false;
					setFunnelData(funnelData);
					Toaster.error('El código ingresado es inválido');
					setIsLoading(false);
					setErrorCoupon(true);
					setErrorInput(true);
					setTotalWithDiscount('');
					setPriceSaved(0);
					setFocusButton(false);
					setValidCoupon(false);
					return;
				}
			}
			const { data } = await getCupon(value, branch, funnelData.step1.plan);
			if (data === undefined || data.cupon === undefined) {
				Toaster.error('El codigo ingresado no existe');
				setDiscount('');
				setIsLoading(false);
				setErrorCoupon(true);
				setPriceSaved(0);
				setErrorInput(true);

				return;
			}
			if (data.cupon.coupon.metadata.readOnly) {
				funnelData.step3.couponIsHidden = true;
				setFunnelData(funnelData);
			}

			if (data.cupon.active === false) {
				Toaster.error('Error. No existe el código de descuento');
				setIsLoading(false);
				setPriceSaved(0);
				return;
			}

			setCoupon(value);

			if (data.cupon.coupon.amount_off) {
				const tmpPago: any =
					funnelData.step1.pago === 'mensual' ? total.mensualPrice.toFixed(2) : total.anualPrice.toFixed(2);
				if (data.cupon.coupon.amount_off / 100 >= tmpPago) {
					Toaster.error('Codigo inválido');
					setIsLoading(false);
					setErrorCoupon(true);
					setErrorInput(true);
					return;
				}

				setValidCoupon(true);
				setDiscount(data.cupon.coupon.amount_off);
				const tmpTotal = (tmpPago - data.cupon.coupon.amount_off / 100).toFixed(2);
				setTotalWithDiscount(tmpTotal);
				const tmpTotalSaved = data.cupon.coupon.amount_off / 100;
				funnelData.step3.validCoupon = true;
				funnelData.step3.hasDiscount = true;
				funnelData.step3.coupon = value;
				funnelData.step3.discountAmount = tmpTotalSaved;
				funnelData.step3.couponId = data.cupon.coupon.id;
				setPriceSaved(tmpTotalSaved);
				setIsLoading(false);
				setHasDiscount(true);
				setIdCoupon(data.cupon.coupon.id);
				setIdPromotionCode(data.cupon.id);
				setErrorCoupon(false);
				setErrorInput(false);
				setIsLoading(false);
				setFunnelData(funnelData);
			} else {
				setValidCoupon(true);
				setDiscount(data.cupon.coupon.percent_off);
				const disc = data.cupon.coupon.percent_off;
				const tmpPago =
					funnelData.step1.pago === 'mensual' ? total.mensualPrice.toFixed(2) : total.anualPrice.toFixed(2);
				const tmpTotal = calculatePriceWithPercent(disc, tmpPago);
				const totalWithDiscount = parseFloat(tmpPago) - parseFloat(tmpTotal);
				setTotalWithDiscount(totalWithDiscount);
				const tmpTotalSaved = calculatePriceSaved(disc, tmpPago);
				funnelData.step3.validCoupon = true;
				funnelData.step3.hasDiscount = true;
				funnelData.step3.coupon = value;
				funnelData.step3.discountAmount = parseFloat(tmpTotalSaved);
				funnelData.step3.couponId = data.cupon.coupon.id;
				setPriceSaved(tmpTotalSaved);
				setIsLoading(false);
				setHasDiscount(true);
				setIdCoupon(data.cupon.coupon.id);
				setIdPromotionCode(data.cupon.id);
				setErrorCoupon(false);
				setErrorInput(false);
				setFunnelData(funnelData);
			}

			return data;
		} else {
			setTotalWithDiscount('');
			setPriceSaved(0);
			setFocusButton(false);
		}
	};

	const calculatePriceWithPercent = (percent: any, num: any) => {
		const priceTotal = ((percent * num) / 100).toFixed(2);
		return Number.parseFloat(priceTotal.toString()).toFixed(2);
	};

	const calculatePriceSaved = (percent: any, num: any) => {
		const priceSaved: Number = (percent / 100) * num;
		return Number.parseFloat(priceSaved.toString()).toFixed(2);
	};

	useEffect(() => {
		async function fetchMyAPI() {
			const stripeKey = getKeyStripe();
			let stripe = await loadStripe(stripeKey);
			setStripeLoad(stripe);

			const card = await stripe?.elements()?.create('card', {
				hidePostalCode: true,
				style: {
					base: {
						color: '#313B46',
						fontSize: '14px',
						'::placeholder': {
							color: '#313B46',
						},
					},
				},
			});

			card?.mount('#cardstripe');
			setCardElement(card);
		}

		fetchMyAPI();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTotal = () => {
		const p = funnel.step1.pago === 'anual' ? funnel.total.anualPrice : funnel.total.mensualPrice;
		let finalPrice = 0;
		if (p && p > 0) {
			if (funnel.step3.discountAmount && funnel.step3.discountAmount > 0) {
				finalPrice = p - funnel.step3.discountAmount;
			} else {
				finalPrice = p;
			}
		}
		return finalPrice?.toFixed(2);
	};

	return (
		<div className="App">
			<LoadingDialog isLoading={isLoading}></LoadingDialog>
			<div className="App-header">
				<FunnelFooterMobile
					className="mobile"
					section="steps"
					active="pago"
					btnLabel="PAGAR"
					linkTo="confirmacion"
					showChevron={true}
				/>
				<FunnelHeader progress="3" />
			</div>

			<form onSubmit={formik.handleSubmit}>
				<div className="mobile">
					<FunnelResumen
						show="mobile"
						data={funnel}
						pago={pago === 'mensual' ? total.mensualPrice : total.anualPrice}
						price={
							pago === 'mensual'
								? total.mensualPrice - funnel.step3.discountAmount
								: total.anualPrice - funnel.step3.discountAmount
						}
						discount={funnel.step3.discountAmount}
						modalClick={showModal}
					/>
				</div>
				<div className="funnel__main">
					<div className="funnel__main__inner">
						<div className="funnel__cols">
							<div className="funnel__col funnel__col-1">
								<Modal show={modalOpen} handleClose={hideModal}>
									<p>{t(`resumen.${resumenType}.resumenTitulo`)}</p>
									{t(`resumen.${resumenType}.masDetalles`)}
								</Modal>
								<Modal show={showLockModal} readOnly={true}>
									<p>Error inesperado</p>
									Hemos detectado demasiados intentos podrías volver a intentar en 24 horas
								</Modal>
								<div>
									<h1 className="h1 p-title">
										Completa tu <br className="mobile" /> información
									</h1>
									<p className="p-pago">
										<img src={info} alt="" className="iconInfo" /> En el próximo paso podrá ingresar información de sus
										propiedades.
									</p>
									<p className="p-pago m-bottom">
										<img src={info} alt="" className="iconInfo" /> Tu suscripción se activará en 72 horas a partir del
										pago.
									</p>
									<div className="card__info__container">
										<TextInput
											className="full-width"
											label="Nombre en la tarjeta"
											name="nombreTarjeta"
											onChange={formik.handleChange}
											value={formik.values.nombreTarjeta}
											errorlabel={formik.errors.cupon}
										/>
										<div className="full-width stripeInput  MuiInputBase-root" id="cardstripe"></div>
									</div>
									<br />
									<h2 className="order__summary">Resumen de orden</h2>
									<div className={`cobertura-payment-content`} style={{ width: '50%' }}>
										{/* <RadioButton
											checkedStatus={pago === 'mensual' ? true : false}
											handleClick={(e) => {
												handleChangePeriodPayment('mensual');
											}}
											price={`${Pricing.formatPrice(total.mensualPrice, branch, 2)}`}
											pricePerc={total.mensualPerc}
											className={`${pago === 'mensual' ? 'active' : ''} `}
											label={'Pago Mensual'}
										></RadioButton> */}
										<RadioButton
											checkedStatus={pago === 'anual' ? true : false}
											handleClick={(e) => {
												handleChangePeriodPayment('anual');
											}}
											price={`${Pricing.formatPrice(total.anualPrice, branch, 2)}`}
											className={`${pago === 'anual' ? 'active' : ''} `}
											pricePerc={total.anualPerc}
											label={'Pago Anual'}
										>
											<div className="active-indicator"></div>
										</RadioButton>
									</div>
									{!funnelData.step3.couponIsHidden && (
										<div className="containerInputs">
											<input
												name="cupon"
												onChange={(e) => {
													handleOnChange(e.target.value.toUpperCase());
												}}
												value={formik.values.cupon}
												placeholder="Cupón de descuento"
												type="text"
												onFocus={() => handleFocus(formik.values.cupon)}
												className={errorInput === false ? 'inputFixed ' : 'errorInput '}
											/>
											{/* eslint-disable jsx-a11y/anchor-is-valid */}
											<a
												onClick={() => handleCupon(formik.values.cupon)}
												className={focusCoupon === true ? 'buttonFixedOrange' : 'buttonFixed'}
											>
												Aplicar
											</a>
										</div>
									)}
									{errorCoupon && <div className="textfield-error-label">Cupón inválido</div>}
									<h2 className="pago-total">
										Total hoy: $
										{priceWithDiscount !== '' && discount !== ''
											? Pricing.formatPrice(priceWithDiscount, branch, 2)
											: pago === 'mensual'
											? Pricing.formatPrice(total.mensualPrice, branch, 2)
											: Pricing.formatPrice(total.anualPrice, branch, 2)}
									</h2>
									<div className="pago-descuento">
										{priceSaved !== '' && priceSaved > 0 && discount !== ''
											? 'Total ahorrado: $ ' + Pricing.formatPrice(priceSaved, branch, 2)
											: ''}
									</div>
									<OrangeButton
										handleClick={formik.handleSubmit}
										className={
											isSend === true
												? 'button-disabled pago-orange-btn full-width mobile button'
												: 'button pago-orange-btn full-width mobile button'
										}
										isDisabled={isSend}
									>
										Proceder con el pago
									</OrangeButton>
								</div>
								<FooterLogos />
							</div>
							<div className="funnel__col funnel__col-2 ctr">
								<div className="desktop">
									{funnelData.step1.productType ? (
										<FunnelResumen
											show="desktop"
											data={funnel}
											pago={pago === 'mensual' ? total.mensualPrice : total.anualPrice}
											price={
												pago === 'mensual'
													? total.mensualPrice - funnel.step3.discountAmount
													: total.anualPrice - funnel.step3.discountAmount
											}
											discount={funnel.step3.discountAmount}
										/>
									) : (
										<FunnelResumenBundle
											show="desktop"
											data={funnel}
											pago={pago === 'mensual' ? total.mensualPrice : total.anualPrice}
											price={
												pago === 'mensual'
													? total.mensualPrice - funnel.step3.discountAmount
													: total.anualPrice - funnel.step3.discountAmount
											}
											modalClick={showModal}
										/>
									)}
								</div>
							</div>
							<FunnelFooterDesktop
								className="desktop"
								section="steps"
								active="pago"
								btnLabel="PAGAR"
								btnIsDisabled={isSend}
								handleClick={formik.handleSubmit}
								showChevron={true}
							/>
						</div>
					</div>
				</div>
				<Recaptcha ref={(ref) => setCapcha(ref)} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onResolved={() => {}} />
			</form>
		</div>
	);
}
