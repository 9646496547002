import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './nuestros-clientes.scss';

import OrangeTitle from '../../orange-title/orange-title';
import leftIcon from '../../../images/svg/left-arrow-slider.svg';
import rightIcon from '../../../images/svg/right-arrow-slider.svg';
import ComunData from '../../../data/comun.json';

interface Props {
	data: {};
}

const communData = ComunData.reviews;

const NuestrosClientes: React.FC<Props> = ({ data }) => {
	const [getPos, setPos] = useState(0);
	const [getIndicator, setIndicator] = useState('nc-slide-container__indicator');

	const slideNext = (ev: number) => {
		const newPos = ev;
		const currentPos = getPos;
		const newDirection = newPos > currentPos ? 'right' : 'left';

		goToIndicator(`${newDirection}`);

		setPos(ev);
	};

	const arrowClick = (side: string) => {
		if (side === 'right' && getPos < 4) {
			setPos(getPos + 1);
			goToIndicator(`right`);
		} else if (side === 'left' && getPos > 0) {
			setPos(getPos - 1);
			goToIndicator(`left`);
		}
	};

	const goToIndicator = (side: string) => {
		setIndicator(`nc-slide-container__indicator nc-slide-container__indicator--${side}`);
	};

	return (
		<div className="nuestros-clientes-home">
			<div className="nuestros-clientes">
				<div className="nc-slide-container" data-pos={getPos}>
					<div className="nc-slide-container-top">
						<div className="nc-slide-container-orange-title">
							<h1 className="title-green">Nuestros Clientes</h1>
						</div>
					</div>
					<div className="nc-slide-container-center">
						<div className="nc-slide-container-center-center">
							<div className="nc-slide">
								<div className="nc-slide-container__slides">
									{communData.map((review) => (
										<div key={review.name} className="nc-slide-container__slide">
											<div className="nc-wrapper">
												<div className="nc-title">“{review.description}”</div>
												<div className="nc-clients">
													<div className="nc-name">{review.name}</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="nc-slide-container-bottom">
						<div className="nc-slide-container-bottom-left">
							<Link onClick={() => arrowClick('left')} to="#" className="nc-arrows nc-arrows--left">
								<img src={leftIcon} alt="" />
							</Link>
						</div>

						<div className="nc-slide-container-bottom-center">
							<div className="nc-slide-container__dots">
								<div className={getIndicator} />
								<Link onClick={() => slideNext(0)} to="#" className="nc-slide-container__dot" />
								<Link onClick={() => slideNext(1)} to="#" className="nc-slide-container__dot" />
								<Link onClick={() => slideNext(2)} to="#" className="nc-slide-container__dot" />
								<Link onClick={() => slideNext(3)} to="#" className="nc-slide-container__dot" />
								<Link onClick={() => slideNext(4)} to="#" className="nc-slide-container__dot" />
							</div>
						</div>

						<div className="nc-slide-container-bottom-right">
							<Link onClick={() => arrowClick('right')} to="#" className="nc-arrows nc-arrows--right">
								<img src={rightIcon} alt="" />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NuestrosClientes;
