import React, { useEffect, useContext } from 'react';

// Accordian Module

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton } from 'react-accessible-accordion';

import './politicas.scss';

import Container from 'components/container/container';
import OrangeTitle from 'components/orange-title/orange-title';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { AppContext } from 'helpers/Context';
import { Helmet } from 'react-helmet';
interface Props {}

const Politicas: React.FC<Props> = () => {
	const { identify, setIdentifyStatus } = useContext(AppContext);
	const { branch } = useContext(AppContext);
	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App empleos">
			<Helmet>
				<title>Políticas - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="hero bg--light-gray-3 ctr tall">
				<div className="container narrow">
					<h1>
						<OrangeTitle>Políticas y Condiciones</OrangeTitle>
					</h1>
					<h2>Condiciones Generales de Productos.</h2>
				</div>
			</div>
			<nav className="jump-nav">
				<Container>
					<div className="inner">
						<div className="jump-nav__label">Condiciones para:</div>
						<ul>
							<li>
								<NavHashLink to="#connect" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
									CONNECT
								</NavHashLink>
							</li>
						</ul>
					</div>
				</Container>
			</nav>
			<div className="accordian__group no-focus-outline" id="connect">
				<Container>
					<div className="faq-pad">
						<OrangeTitle>CONNECT</OrangeTitle>
					</div>
					<Accordion allowZeroExpanded>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__politicas">
									<a
										className="blackLink"
										rel="noopener noreferrer"
										target="_blank"
										href="https://connect-assistant-public-assets.s3.amazonaws.com/connect/puerto-rico/Contrato-de-servicios-pr_202306.pdf"
									>
										Condiciones de Plan de Asistencia en Carretera
									</a>
								</AccordionItemButton>
							</AccordionItemHeading>
						</AccordionItem>

						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__politicas">
									<a
										className="blackLink"
										rel="noopener noreferrer"
										target="_blank"
										href="https://connect-assistant-public-assets.s3.amazonaws.com/connect/puerto-rico/Contrato-de-home-pr.pdf"
									>
										Condiciones de Plan de Asistencia en el Hogar
									</a>
								</AccordionItemButton>
							</AccordionItemHeading>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton className="accordion__item__button__politicas">
									<a
										className="blackLink"
										rel="noopener noreferrer"
										target="_blank"
										href="https://connect-assistant-public-assets.s3.amazonaws.com/connect/puerto-rico/Reglamento-Promocion-Connect-te-llena-el-tanque.pdf"
									>
										Reglamento de la promoción “Connect te llena el tanque”
									</a>
								</AccordionItemButton>
							</AccordionItemHeading>
						</AccordionItem>
					</Accordion>
				</Container>
			</div>
			<div className="hero dark ctr">
				<div className="container narrow">
					<h1>
						<OrangeTitle className="ctr">Contacto</OrangeTitle>
					</h1>
					<h2>¿Toadavía tienes preguntas?</h2>
					<p className="detailJob">No te preocupes. Estamos aquí para asistir.</p>
					<a href="mailto:assist@fulcroinsurance.com" className="button ctr">
						Contactanos
					</a>
				</div>
			</div>
		</div>
	);
};
export default Politicas;
