import React, { useEffect, useContext } from 'react';
import './politicasHabeasData.scss';
import OrangeTitle from 'components/orange-title/orange-title';
import { Link } from 'react-router-dom';
import { AppContext } from 'helpers/Context';
import { Helmet } from 'react-helmet';
interface Props {}

const PoliticasHabeasData: React.FC<Props> = () => {
	const { identify, setIdentifyStatus } = useContext(AppContext);

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App empleos">
			<Helmet>
				<title>Políticas - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="hero bg--light-gray-3 ctr tall">
				<div className="container narrow">
					<h1>
						<OrangeTitle>Políticas y Condiciones</OrangeTitle>
					</h1>
				</div>
			</div>
			<div className="container">
				<br></br>
				<h2>Política de Habeas Data.</h2>
				<p>
					En virtud del fuerte compromiso que existe en CONNECT ASSISTANCE S.A.S., con el adecuado tratamiento de datos
					personales, garantizando además de la salvaguarda y seguridad de la información, el ejercicio del Habeas Data,
					la empresa establece la presenta Política aplicables para la seguridad de la información en la organización.
				</p>
				<b>AGOSTO DE 2020</b>
				<h2>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</h2>
				<p>
					En cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012 y sus Decretos Reglamentarios, la Empresa
					establece la Pol&iacute;tica General y Especiales aplicables para el Tratamiento y Protecci&oacute;n de Datos
					Personales en la organizaci&oacute;n.
				</p>
				<ol>
					<li>
						<strong></strong> <strong>IDENTIFICACI&Oacute;N</strong> <strong>DEL RESPONSABLE:</strong>
					</li>
				</ol>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> sociedad comercial identificada con NIT 900-951.215-1, con
					domicilio principal en la ciudad de Bogot&aacute; D.C. y con casa matriz en Per&uacute;, se constituye como
					una empresa colombiana dedicada a prestar servicios de asistencia, log&iacute;stica y contact center con una
					amplia variedad y l&iacute;neas de inter&eacute;s para el consumidor, como lo son: hogar, veh&iacute;culos,
					mascotas, orientaci&oacute;n m&eacute;dica, viajes, entre otras, lo anterior a nivel familiar o corporativo y
					a trav&eacute;s de canales presenciales o no tradicionales (call center), esto con fundamento en lo
					establecido en el Estatuto del Consumidor (Ley 1480 de 2011).
				</p>
				<ul>
					<li>
						<strong>DIRECCI&Oacute;N F&Iacute;SICA:</strong> Carrera 14 #97-63
					</li>
					<li>
						<strong>CORREO ELECTR&Oacute;NICO DE CONTACTO:</strong> <u>info@connect.com.co</u>
					</li>
					<li>
						<strong>P&Aacute;GINA WEB:</strong> <u>www.connect.com.co</u>
					</li>
					<li>
						<strong>TEL&Eacute;FONO:</strong> 031-4322350
					</li>
				</ul>
				<ol start={2}>
					<li>
						<strong></strong> <strong>OBJETIVO</strong>
					</li>
				</ol>
				<p>
					La presente Pol&iacute;tica establece las directrices generales para la protecci&oacute;n y el tratamiento de
					datos personales al interior de permitiendo de esta manera fortalecer el nivel de confianza entre Responsable
					y Titulares con relaci&oacute;n al tratamiento de su informaci&oacute;n; Informar a los Titulares de las
					finalidades y transferencias a que son sometidos sus datos personales y los mecanismos y formas para el
					ejercicio de sus derechos.
				</p>
				<ol start={3}>
					<li>
						<strong></strong> <strong>ALCANCE</strong>
					</li>
				</ol>
				<p>
					Esta Pol&iacute;tica de Tratamiento y Protecci&oacute;n de Datos Personales ser&aacute; aplicada a todas las
					bases de datos y/o archivos que incluyan datos personales que sean objeto de tratamiento por parte de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong> como responsable del tratamiento de datos personales.
				</p>
				<p>
					La presente constituye la versi&oacute;n 2.0 de pol&iacute;tica de tratamiento de datos personales de la
					empresa, lo anterior, con base en el proceso de actualizaci&oacute;n de bases de datos que realiz&oacute; la
					empresa dando cumplimiento al Decreto 090 de 2018 y la Circular Externa 003 del 30 de marzo de 2020, esto es
					la actualizaci&oacute;n que debe realizar toda empresa de sus bases de datos{' '}
					<strong>
						<u>antes del 03 de julio de 2020.</u>
					</strong>
				</p>
				<ol start={4}>
					<li>
						<strong></strong> <strong>DEFINICIONES</strong>
					</li>
				</ol>
				<ul>
					<li>
						<strong>- Habeas Data:</strong> Derecho que tiene toda persona de conocer, actualizar y rectificar la
						informaci&oacute;n que se haya recogido sobre ella en archivos y bancos de datos de naturaleza
						p&uacute;blica o privada.
					</li>
					<li>
						<strong>- Dato personal:</strong> Cualquier informaci&oacute;n vinculada o que pueda asociarse a una o
						varias personas naturales determinadas o determinables.
					</li>
					<li>
						<strong>- Base de Datos:</strong> Conjunto organizado de datos personales que sea objeto de tratamiento.
					</li>
					<li>
						<strong>- Tratamiento:</strong> Cualquier operaci&oacute;n o conjunto de operaciones sobre datos personales,
						tales como la recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n.
					</li>
					<li>
						<strong>- Autorizaci&oacute;n:</strong> Consentimiento previo, expreso e informado del Titular para llevar a
						cabo el tratamiento de datos personales.
					</li>
					<li>
						<strong>- Aviso de privacidad:</strong> Es el documento f&iacute;sico, electr&oacute;nico o en cualquier
						otro formato conocido o por conocer, que es puesto a disposici&oacute;n del Titular con el fin de informar
						sobre el tratamiento de sus datos personales.
					</li>
					<li>
						<strong>- Titular:</strong> Persona natural cuyos datos personales sean objeto de tratamiento
					</li>
					<li>
						<strong>- Causahabiente:</strong> Persona que por sucesi&oacute;n o transmisi&oacute;n adquiere los derechos
						de otra persona.
					</li>
					<li>
						<strong>- Responsable del Tratamiento:</strong> Persona natural o jur&iacute;dica, p&uacute;blica o privada,
						que por s&iacute; misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los
						datos.
					</li>
					<li>
						<strong>- Encargado del Tratamiento:</strong> Persona natural o jur&iacute;dica, p&uacute;blica o privada,
						que por s&iacute; misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del
						Responsable del tratamiento.
					</li>
				</ul>
				<ol start={5}>
					<li>
						<strong></strong> <strong>PRINCIPIOS RECTORES</strong>{' '}
						<strong>APLICABLES EN MATERIA DE DATOS PERSONALES</strong>
					</li>
				</ol>
				<p>
					En materia de protecci&oacute;n de datos personales se aplicar&aacute;n los siguientes principios rectores:
				</p>
				<ol type="a">
					<li>
						<strong>Principio de legalidad en materia de Tratamiento de datos:</strong> El tratamiento a que se refiere
						la Ley de Habeas Data es una actividad reglada que debe sujetarse a lo establecido en ella y en las
						dem&aacute;s disposiciones que la desarrollen.
					</li>
					<li>
						<strong>Principio de finalidad:</strong> El tratamiento debe obedecer a una finalidad leg&iacute;tima de
						acuerdo con la Constituci&oacute;n y la ley, la cual debe ser informada al Titular.
					</li>
					<li>
						<strong>Principio de libertad:</strong> El tratamiento s&oacute;lo puede ejercerse con el consentimiento,
						previo, expreso e informado del Titular. Los datos personales no podr&aacute;n ser obtenidos o divulgados
						sin previa autorizaci&oacute;n, o en ausencia de mandato legal o judicial que releve el consentimiento.
					</li>
					<li>
						<strong>Principio de veracidad o calidad:</strong> La informaci&oacute;n sujeta a tratamiento debe ser
						veraz, completa, exacta, actualizada, comprobable y comprensible. Se proh&iacute;be el tratamiento de datos
						parciales, incompletos, fraccionados o que induzcan a error.
					</li>
					<li>
						<strong>Principio de transparencia:</strong> En el tratamiento debe garantizarse el derecho del Titular a
						obtener del Responsable del tratamiento o del Encargado del tratamiento, en cualquier momento y sin
						restricciones, informaci&oacute;n acerca de la existencia de datos que le conciernan.
					</li>
					<li>
						<strong>Principio de acceso y circulaci&oacute;n restringida:</strong> El tratamiento se sujeta a los
						l&iacute;mites que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la
						Constituci&oacute;n. En este sentido, el tratamiento s&oacute;lo podr&aacute; hacerse por personas
						autorizadas por el Titular y/o por las personas previstas en la ley.
						<p>
							Los datos personales, salvo la informaci&oacute;n p&uacute;blica, no podr&aacute;n estar disponibles en
							Internet u otros medios de divulgaci&oacute;n o comunicaci&oacute;n masiva, salvo que el acceso sea
							t&eacute;cnicamente controlable para brindar un conocimiento restringido s&oacute;lo a los Titulares o
							terceros autorizados conforme a la ley.
						</p>
					</li>

					<li>
						<strong>Principio de seguridad:</strong> La informaci&oacute;n sujeta a tratamiento por el Responsable del
						tratamiento o Encargado del tratamiento a que se refiere la Ley de Habeas Data, se deber&aacute; manejar con
						las medidas t&eacute;cnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los
						registros evitando su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o
						fraudulento.
					</li>
					<li>
						<strong>Principio de confidencialidad:</strong> Todas las personas que intervengan en el tratamiento de
						datos personales que no tengan la naturaleza de p&uacute;blicos est&aacute;n obligadas a garantizar la
						reserva de la informaci&oacute;n, inclusive despu&eacute;s de finalizada su relaci&oacute;n con alguna de
						las labores que comprende el tratamiento, pudiendo s&oacute;lo realizar suministro o comunicaci&oacute;n de
						datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y en los
						t&eacute;rminos de la misma.
					</li>
				</ol>
				<ol start={6}>
					<li>
						<strong></strong> <strong>DERECHOS DE LOS TITULARES</strong>
					</li>
				</ol>
				<p>
					Los Titulares de datos personales gozar&aacute;n de los siguientes derechos, y de aquellos que les otorgue la
					ley:
				</p>
				<ol type="a">
					<li>
						Conocer, actualizar y rectificar sus datos personales frente al Responsable del tratamiento o Encargados del
						tratamiento. Este derecho se podr&aacute; ejercer, entre otros frente a datos parciales, inexactos,
						incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento est&eacute; expresamente
						prohibido o no haya sido autorizado;
					</li>
					<li>
						Solicitar prueba de la autorizaci&oacute;n otorgada al Responsable del tratamiento salvo cuando expresamente
						se except&uacute;e como requisito para el tratamiento, de conformidad con lo previsto en el art&iacute;culo
						10 de la ley;
					</li>
					<li>
						Ser informado por el Responsable del tratamiento o el Encargado del tratamiento, previa solicitud, respecto
						del uso que les ha dado a sus datos personales;
					</li>
					<li>
						Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley
						y las dem&aacute;s normas que la modifiquen, adicionen o complementen;
					</li>
					<li>
						Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del dato cuando en el tratamiento no se
						respeten los principios, derechos y garant&iacute;as constitucionales y legales. La revocatoria y/o
						supresi&oacute;n proceder&aacute; cuando la Superintendencia de Industria y Comercio haya determinado que en
						el tratamiento el Responsable o Encargado han incurrido en conductas contrarias a la ley y a la
						Constituci&oacute;n;
					</li>
					<li>Acceder en forma gratuita a sus datos personales que hayan sido objeto de tratamiento.</li>
				</ol>
				<p>
					<br />{' '}
				</p>
				<ol start={7}>
					<li>
						<strong></strong> <strong>AUTORIZACI&Oacute;N DEL TITULAR DE DATOS PERSONALES</strong>
					</li>
				</ol>
				<p>
					Sin perjuicio de las excepciones previstas en la Ley Estatutaria 1581 de 2012, por regla general en el
					tratamiento de datos de car&aacute;cter personal <strong>CONNECT ASSISTANCE S.A.S..,</strong>{' '}
					recolectar&aacute; la autorizaci&oacute;n previa e informada del Titular, la cual podr&aacute; ser obtenida
					por cualquier medio que pueda ser objeto de consulta posterior.
				</p>
				<p>
					<strong>7.1</strong> <strong>Eventos en los cuales no es necesaria la autorizaci&oacute;n</strong>
				</p>
				<p>La autorizaci&oacute;n del Titular no ser&aacute; necesaria cuando se trate de:</p>
				<ol type="a">
					<li>
						Informaci&oacute;n requerida por una entidad p&uacute;blica o administrativa en ejercicio de sus funciones
						legales o por orden judicial;
					</li>
					<li>Datos de naturaleza p&uacute;blica;</li>
					<li>Casos de urgencia m&eacute;dica o sanitaria;</li>
					<li>
						Tratamiento de informaci&oacute;n autorizado por la ley para fines hist&oacute;ricos, estad&iacute;sticos o
						cient&iacute;ficos;
					</li>
					<li>Datos relacionados con el Registro Civil de las Personas.</li>
				</ol>
				<p>
					<br />{' '}
				</p>
				<ol start={8}>
					<li>
						<strong></strong> <strong>DEBERES DE</strong> <strong>CONNECT ASSISTANCE S.A.S..</strong>{' '}
						<strong>COMO RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</strong>
					</li>
				</ol>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..</strong> como responsable del tratamiento de datos personales,
					cumplir&aacute; los siguientes deberes:
				</p>
				<ol type="a">
					<li>
						Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de h&aacute;beas data.
					</li>
					<li>
						Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva autorizaci&oacute;n
						otorgada por el Titular.
					</li>
					<li>
						Informar debidamente al Titular sobre la finalidad de la recolecci&oacute;n y los derechos que le asisten
						por virtud de la autorizaci&oacute;n otorgada.
					</li>
					<li>
						Conservar la informaci&oacute;n bajo las condiciones de seguridad necesarias para impedir su
						adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.
					</li>
					<li>
						Garantizar que la informaci&oacute;n que se suministre al Encargado del tratamiento sea veraz, completa,
						exacta, actualizada, comprobable y comprensible.
					</li>
					<li>
						Actualizar la informaci&oacute;n, comunicando de forma oportuna al Encargado del tratamiento, todas las
						novedades respecto de los datos que previamente le haya suministrado y adoptar las dem&aacute;s medidas
						necesarias para que la informaci&oacute;n suministrada a este se mantenga actualizada.
					</li>
					<li>
						Rectificar la informaci&oacute;n cuando sea incorrecta y comunicar lo pertinente al Encargado del
						tratamiento.
					</li>
					<li>
						Suministrar al Encargado del tratamiento, seg&uacute;n el caso, &uacute;nicamente datos cuyo tratamiento
						est&eacute; previamente autorizado de conformidad con lo previsto en la presente ley.
					</li>
					<li>
						Exigir al Encargado del tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad
						de la informaci&oacute;n del Titular.
					</li>
					<li>
						Tramitar las consultas y reclamos formulados en los t&eacute;rminos se&ntilde;alados en la Ley Estatutaria
						1581 de 2012.
					</li>
					<li>
						Adoptar un manual interno de pol&iacute;ticas y procedimientos para garantizar el adecuado cumplimiento de
						la ley y en especial, para la atenci&oacute;n de consultas y reclamos.
					</li>
					<li>
						Informar al Encargado del tratamiento cuando determinada informaci&oacute;n se encuentra en discusi&oacute;n
						por parte del Titular, una vez se haya presentado la reclamaci&oacute;n y no haya finalizado el
						tr&aacute;mite respectivo.
					</li>
					<li>Informar a solicitud del Titular sobre el uso dado a sus datos.</li>
					<li>
						Informar a la autoridad de protecci&oacute;n de datos cuando se presenten violaciones a los c&oacute;digos
						de seguridad y existan riesgos en la administraci&oacute;n de la informaci&oacute;n de los Titulares.
					</li>
					<li>Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</li>
				</ol>
				<p>
					<br />{' '}
				</p>
				<ol start={9}>
					<li>
						<strong></strong>{' '}
						<strong>POL&Iacute;TICAS ESPEC&Iacute;FICAS PARA EL TRATAMIENTO DE DATOS PERSONALES.</strong>
					</li>
				</ol>
				<p>
					<strong>9.1</strong> <strong>Tratamiento de datos personales de Empleados</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..</strong> recolecta los datos personales de sus{' '}
					<strong>Trabajadores</strong> los cuales son calificados por la compa&ntilde;&iacute;a como de reserva, y solo
					ser&aacute;n revelados por la Empresa con la expresa autorizaci&oacute;n del titular o cuando una Autoridad
					Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizados los datos personales de los empleados de la Empresa
					ser&aacute;n:
				</p>
				<ol type="a">
					<li>
						Dar cumplimiento a las obligaciones que impone la Ley Laboral colombiana a los empleadores, o bien las
						&oacute;rdenes que impartan las autoridades colombianas o extranjeras competentes;
					</li>
					<li>Emitir certificaciones relativas a la relaci&oacute;n del titular del dato con la Empresa.</li>
					<li>
						Cumplir con las obligaciones impuestas a la Empresa como empleador, en relaci&oacute;n con las normas de
						Seguridad y Salud Ocupacional, y el denominado Sistema de Gesti&oacute;n de Seguridad y Salud en el Trabajo
						(SG-SST).
					</li>
					<li>Cumplir con los protocolos de bioseguridad aplicables a la Empresa.</li>
					<li>Gestionar las funciones desarrolladas por los trabajadores.</li>
					<li>Consultar memorandos o llamados de atenci&oacute;n.</li>
					<li>Desarrollar y aplicar el proceso disciplinario.</li>
					<li>Establecer comunicaci&oacute;n en casos de emergencia.</li>
					<li>Llevar a cabo un control, seguimiento y evaluaci&oacute;n de los trabajadores.</li>
					<li>Proteger los materiales confidenciales y de propiedad de A365.</li>
					<li>
						Tomar im&aacute;genes, fotogr&aacute;ficas y huella digital necesarios para el reconocimiento del
						trabajador, control de cumplimiento y recolecci&oacute;n de evidencia de los servicios desarrollados.
					</li>
					<li>
						Adicionalmente se usan datos biom&eacute;tricos de los trabajadores para fines comerciales relacionados con
						el objeto social de la Empresa.
					</li>
					<li>
						Comunicar informaci&oacute;n de los trabajadores a terceras entidades con las que la Empresa mantenga una
						relaci&oacute;n contractual o en los casos donde sea necesario para cumplir con el protocolo de los
						terceros, con los &uacute;nicos efectos de que estos puedan gestionar el control y coordinaci&oacute;n
						respecto del personal que preste de manera efectiva los servicios que se derivan de la relaci&oacute;n
						contractual, as&iacute; como posibilitar el cumplimiento de obligaciones legales, en materia fiscal y de
						seguridad social.
					</li>
					<li>
						Comunicar datos de car&aacute;cter identificativo de los trabajadores a agencias de viajes,
						compa&ntilde;&iacute;as de transportes, hoteles y entidades de alquiler de veh&iacute;culos, para la
						gesti&oacute;n de las reservas y liquidaci&oacute;n de los gastos ocasionados.
					</li>
					<li>
						Entrega de la informaci&oacute;n a terceros encargados de procesos de evaluaci&oacute;n, entrenamiento,
						certificaci&oacute;n, y dem&aacute;s procesos requeridos en el desarrollo de la relaci&oacute;n contractual.
					</li>
					<li>Verificar, comparar, evaluar las competencias laborales y personales de los empleados.</li>
					<li>
						Env&iacute;o de informaci&oacute;n a cajas de compensaci&oacute;n, AFP, ARL, aseguradoras entre otros.
					</li>
					<li>Iniciar investigaciones internas con base en las quejas presentadas por clientes.</li>
					<li>
						Gesti&oacute;n de denuncias a trabajadores por acoso laboral o violaci&oacute;n de c&oacute;digos de
						conducta.
					</li>
				</ol>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..</strong> almacena los datos personales de sus empleados, incluidos los que
					hayan sido obtenidos en desarrollo del proceso de selecci&oacute;n, y los conserva en una carpeta identificada
					con el nombre de cada uno de ellos.
				</p>
				<p>
					A tal carpeta solo tendr&aacute; acceso y ser&aacute; tratada por el &Aacute;rea de Gesti&oacute;n Humana, con
					la finalidad de administrar la relaci&oacute;n contractual entre <strong>CONNECT ASSISTANCE S.A.S..</strong> y
					el empleado.
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..</strong> trata Datos Personales Sensibles de sus trabajadores como la
					huella dactilar a trav&eacute;s de control biom&eacute;trico con la &uacute;nica finalidad de controlar el
					ingreso a las instalaciones de la compa&ntilde;&iacute;a y proporcionar ambientes de trabajo seguros. Para los
					efectos de este Tratamiento se recolecta la respectiva autorizaci&oacute;n que en todo caso ser&aacute;
					expresa y facultativa, indicando claramente los Datos Personales Sensibles objeto de Tratamiento y la
					finalidad del mismo.
				</p>
				<p>
					As&iacute; mismo, contar&aacute; con elevados sistemas de seguridad para el manejo de aquellos datos sensibles
					y su reserva, en el entendido que tales datos sensibles solo ser&aacute;n usados por{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong> para los fines antes mencionados.
				</p>
				<p>
					Terminada la relaci&oacute;n laboral, <strong>CONNECT ASSISTANCE S.A.S..</strong> proceder&aacute; a almacenar
					todos los datos personales que haya obtenido del proceso de selecci&oacute;n y la documentaci&oacute;n
					generada en desarrollo de la relaci&oacute;n laboral, en un archivo central con acceso restringido, sometiendo
					en todo momento la informaci&oacute;n a medidas y niveles de seguridad adecuados, dado que la
					informaci&oacute;n laboral pueda contener datos de car&aacute;cter sensible.
				</p>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior a{' '}
					<strong>veinte (20) a&ntilde;os</strong>contados a partir de la terminaci&oacute;n de la relaci&oacute;n
					laboral, o de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la
					informaci&oacute;n.
				</p>
				<p>
					<strong>9.2</strong> <strong>Tratamiento de datos personales de Aprendices SENA:</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..</strong> recolecta los datos personales de los aprendices SENA y los
					almacena en una base de datos la cual es calificada por la Empresa como de reserva, y s&oacute;lo ser&aacute;
					revelada con la expresa autorizaci&oacute;n del titular o cuando una Autoridad Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizados los datos personales de los aprendices SENA del{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>
						Dar cumplimiento a las obligaciones que impone la ley laboral colombiana a las Empresas patrocinadoras, en
						especial a lo dispuesto en la Ley 789 de 2002 as&iacute; como sus Decretos Reglamentarios.
					</li>
					<li>Emitir certificaciones relativas a la relaci&oacute;n del titular del dato con la Empresa.</li>
					<li>
						Corroborar cualquier requerimiento que se ocasione en desarrollo del proceso de vinculaci&oacute;n de
						aprendices.
					</li>
					<li>
						Cumplir con las obligaciones impuestas a la Empresa como Empresa patrocinadora, en relaci&oacute;n con las
						normas de Seguridad y Salud Ocupacional, y el denominado Sistema de Gesti&oacute;n de Seguridad y Salud en
						el Trabajo (SG-SST).
					</li>
					<li>Gestionar las funciones desarrolladas por los aprendices.</li>
					<li>Controlar el desarrollo de los aprendices en la etapa lectiva y etapa productiva.</li>
					<li>Contactar a familiares en casos de emergencia.</li>
				</ol>
				<p>
					Como quiera que el contrato de aprendizaje al interior de las Empresas patrocinadoras se enmarca en el
					&aacute;mbito de las relaciones laborales, los respectivos soportes documentales son manejados y custodiados
					por parte de <strong>CONNECT ASSISTANCE S.A.S..</strong> como historia laboral por las &aacute;reas encargadas
					de la administraci&oacute;n del talento humano.
				</p>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior al
					tiempo que dure la relaci&oacute;n del postulante con la Empresa, que en ning&uacute;n caso podr&aacute; ser
					superior a <strong>dos (2) a&ntilde;os</strong>, y el tiempo adicional que se requiera de acuerdo con las
					circunstancias legales o contractuales que hagan necesario el manejo de la informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.3</strong> <strong>Tratamiento de datos personales de clientes y/o consumidores:</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta los datos personales de sus Clientes personas naturales
					y jur&iacute;dicos, esto es a nivel corporativo como a nivel consumidor, y los almacena en una base de datos
					la cual es calificada por la Empresa como de reserva, y solo ser&aacute; revelada con la expresa
					autorizaci&oacute;n del titular o cuando una Autoridad Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizados los datos personales de los clientes de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>Proceso de control y registro contable de las obligaciones contra&iacute;das con los clientes.</li>
					<li>Cumplimiento de aspectos tributarios y legales ante entidades p&uacute;blicas y regulatorias.</li>
					<li>
						Cumplimiento de decisiones judiciales y disposiciones administrativas, legales, fiscales y regulatorias.
					</li>
					<li>Transmisi&oacute;n de informaci&oacute;n y datos personales en procesos de auditor&iacute;as.</li>
					<li>
						Gesti&oacute;n administrativa para la ejecuci&oacute;n de las etapas precontractual, contractual y pos
						contractual.
					</li>
					<li>Creaci&oacute;n del cliente en las plataformas o software de la Empresa.</li>
					<li>Llevar control y seguimiento sobre la prestaci&oacute;n del servicio de asistencia.</li>
					<li>Realizar encuestas de satisfacci&oacute;n y prospecci&oacute;n comercial.</li>
					<li>Trasferir o transmitir informaci&oacute;n de inter&eacute;s tanto a la casa matriz.</li>
					<li>
						Garantizar los derechos que le asisten a las personas en virtud del Estatuto del Consumidor (Ley 1480 de
						2011).
					</li>
					<li>
						Dar tr&aacute;mite a las peticiones, quejas o reclamos establecidas de manera directa por el consumidor a
						trav&eacute;s de los canales de atenci&oacute;n al cliente.
					</li>
				</ol>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior al
					tiempo que dure la relaci&oacute;n contractual entre el cliente y la Empresa, y el tiempo adicional que se
					requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la
					informaci&oacute;n. En caso de los consumidores personas naturales, se conservar&aacute; la informaci&oacute;n
					hasta por cinco (5) a&ntilde;os, teniendo en cuenta las posibles reclamaciones que se puedan realizar de
					acuerdo con lo establecido en el Estatuto del Consumidor (Ley 1480 de 2011).
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.4</strong> <strong>Tratamiento de datos personales de Proveedores y contratistas:</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta los datos personales de sus Proveedores y contratistas
					y los almacena en una base de datos la cual, aunque se compone en su mayor&iacute;a de datos p&uacute;blicos,
					es calificada por la compa&ntilde;&iacute;a como de reserva, y que, en el caso de datos privados, solo los
					revelar&aacute; por la Empresa con la expresa autorizaci&oacute;n del titular o cuando una Autoridad
					Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizadas los datos personales de los Proveedores o contratistas de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>Llevar a cabo procesos de evaluaciones y selecci&oacute;n de proveedores.</li>
					<li>
						Procesos de vinculaci&oacute;n, creaci&oacute;n y evaluaci&oacute;n del proveedor en los sistemas de
						informaci&oacute;n de la Empresa .
					</li>
					<li>Cumplimiento de aspectos tributarios y legales ante entidades p&uacute;blicas y regulatorias</li>
					<li>Establecer relaciones de negocio para adquirir bienes o servicios</li>
					<li>Llevar gesti&oacute;n y control de los pagos realizados por los bienes y servicios recibidos.</li>
					<li>Evaluaciones cualitativas y cuantitativas de los niveles de servicio recibidos de los proveedores.</li>
					<li>
						Dar cumplimiento a las obligaciones y requerimientos establecidos en el Sistema de Gesti&oacute;n de
						Calidad.
					</li>
					<li>
						Comunicaci&oacute;n de Pol&iacute;ticas y procedimientos sobre la forma de hacer negocios con los
						proveedores.
					</li>
					<li>Proceso de control y registro contable de las obligaciones contra&iacute;das con los proveedores.</li>
					<li>Consultas, auditorias y revisiones derivadas de la relaci&oacute;n de negocio con el proveedor.</li>
					<li>
						Env&iacute;o de invitaciones a contratar y realizaci&oacute;n de gestiones para las etapas precontractual,
						contractual y poscontractual.
					</li>
					<li>
						Transferir o dar a conocer informaci&oacute;n de la relaci&oacute;n contractual con el proveedor a la casa
						matriz
					</li>
					<li>Env&iacute;o de invitaciones a eventos programados por la Compa&ntilde;&iacute;a.</li>
					<li>
						Las dem&aacute;s establecidas espec&iacute;ficamente en las autorizaciones que sean otorgadas por los
						propios proveedores.
					</li>
				</ol>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..</strong> s&oacute;lo recaudar&aacute; de sus proveedores y contratistas los
					datos que sean necesarios, pertinentes y no excesivos para realizar el proceso de selecci&oacute;n,
					evaluaci&oacute;n y ejecuci&oacute;n del contrato a que haya lugar.
				</p>
				<p>
					La recolecci&oacute;n de los datos personales de empleados de los proveedores y contratistas por parte de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> tendr&aacute; en todo caso como finalidad verificar la idoneidad
					y competencia de los empleados; es decir, una vez verificado este requisito,{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong> devolver&aacute; o eliminar&aacute; tal informaci&oacute;n, salvo
					cuando se autorice expresamente su conservaci&oacute;n.
				</p>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior al
					tiempo que dure la relaci&oacute;n del Proveedor o contratista con la Empresa, y el tiempo adicional que se
					requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la
					informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.5</strong> <strong>Tratamiento de datos personales de visitantes en el Control de Ingreso:</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta los datos personales de sus visitantes y los almacena
					en una base de datos la cual es calificada por la Empresa como de reserva, y solo ser&aacute; revelada por la
					Empresa con la expresa autorizaci&oacute;n del titular o cuando una Autoridad Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizadas los datos personales de quienes ingresan a las instalaciones de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>
						Asegurar el ingreso a las instalaciones de la Empresa a personas que cuentan con la autorizaci&oacute;n de
						libre tr&aacute;nsito y restringir el paso a aquellas personas que no est&eacute;n autorizadas.
					</li>
					<li>Garantizar la seguridad en los ambientes vigilados.</li>
					<li>
						Permitir ambientes de trabajo adecuados para el desarrollo seguro de las actividades al interior de la
						Empresa.
					</li>
					<li>
						Dar cumplimiento a las obligaciones estipuladas dentro del Sistema de Gesti&oacute;n de Seguridad y Salud en
						el Trabajo.
					</li>
					<li>
						Cumplir con los requerimientos estipulados dentro de los protocolos de bioseguridad implementados por la
						Empresa.
					</li>
				</ol>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior a un
					(1) a&ntilde;o contado a partir de su recolecci&oacute;n de acuerdo con las circunstancias legales o
					contractuales que hacen necesario el manejo de la informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.6</strong> <strong>Tratamiento de datos personales del Registro de Videovigilancia</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta datos biom&eacute;tricos de sus trabajadores y
					visitantes a trav&eacute;s de sus C&aacute;maras de Vigilancia y los almacena en una base de datos la cual es
					calificada por la compa&ntilde;&iacute;a como de reserva, y solo ser&aacute; revelada con la expresa
					autorizaci&oacute;n del titular o cuando una Autoridad Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizadas los datos personales contenidos en las C&aacute;maras de
					Vigilancia de <strong>CONNECT ASSISTANCE S.A.S..,</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>Garantizar la seguridad en los ambientes laborales e instalaciones de la Empresa.</li>
					<li>
						Permitir ambientes de trabajo adecuados para el desarrollo seguro de actividades laborales de la
						compa&ntilde;&iacute;a.
					</li>
					<li>
						Controlar el ingreso, permanencia y salida de empleados, contratistas y terceros en las instalaciones de la
						Empresa.
					</li>
					<li>
						Servir de soporte probatorio en la ejecuci&oacute;n de procesos disciplinarios en contra de los
						colaboradores que comentan cualquier tipo de infracci&oacute;n.
					</li>
					<li>
						Iniciar investigaciones internas cuando se presente un incidente o accidente en las instalaciones
						f&iacute;sicas de la Empresa.
					</li>
				</ol>
				<p>
					Para cumplir con el deber de informaci&oacute;n que le corresponde a{' '}
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> como administrador de datos personales, la Empresa
					implementar&aacute; Avisos de Privacidad en las zonas en donde se realice la captura de im&aacute;genes que
					impliquen tratamiento de datos personales.
				</p>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior a{' '}
					<strong>noventa (90) d&iacute;as</strong>contados a partir de su recolecci&oacute;n de acuerdo con las
					circunstancias legales o contractuales que hacen necesario el manejo de la informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.7</strong> <strong>Tratamiento de datos personales del Control Biom&eacute;trico</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta datos biom&eacute;tricos de sus trabajadores y los
					almacena en una base de datos la cual es calificada por la compa&ntilde;&iacute;a como de reserva, y solo
					ser&aacute; revelada con la expresa autorizaci&oacute;n del titular o cuando una Autoridad Competente lo
					solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizadas los datos personales contenidos en el control biom&eacute;trico
					de<strong>CONNECT ASSISTANCE S.A.S..,</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>Garantizar la seguridad en los ambientes laborales.</li>
					<li>Permitir acceso solo al personal autorizado.</li>
					<li>
						Controlar el ingreso, permanencia y salida de empleados y contratistas en las instalaciones de la Empresa.
					</li>
					<li>Calcular horas extras y compensaci&oacute;n de jornadas laborales.</li>
					<li>
						Iniciar procesos disciplinarios en caso de incumplir con el horario laboral establecido por la Empresa.
					</li>
				</ol>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior a lo
					que dure la relaci&oacute;n laboral con el trabajador o contratista y de acuerdo con las circunstancias
					legales o contractuales que hacen necesario el manejo de la informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.8</strong> <strong>Tratamiento de datos personales de P&aacute;ginas Web y redes sociales:</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta datos personales privados de terceros a trav&eacute;s
					de su p&aacute;gina web y los almacena en una base de datos la cual es calificada por la
					compa&ntilde;&iacute;a como de reserva, y solo ser&aacute; revelada con la expresa autorizaci&oacute;n del
					titular o cuando una Autoridad Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizados los datos personales que son recopilados a trav&eacute;s de la
					p&aacute;gina web de <strong>CONNECT ASSISTANCE S.A.S..</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>
						Permitir la comunicaci&oacute;n con los clientes o terceros mediante la secci&oacute;n de
						cont&aacute;ctenos.
						<p>Dar a conocer los servicios de asistencia, log&iacute;stica y contact center de la Empresa .</p>
					</li>

					<li>Publicar eventos o noticias de inter&eacute;s.</li>
					<li>Recepcionar y dar tramite a las quejas y reclamos de los consumidores.</li>
					<li>
						Fines legales, contables, administrativos, comerciales, promocionales, informativos, de mercadeo y ventas.
					</li>
					<li>Realizar campa&ntilde;as de promoci&oacute;n, marketing, publicidad.</li>
					<li>
						Garantizar los derechos que le asisten a las personas en virtud del Estatuto del Consumidor (Ley 1480 de
						2011).
					</li>
					<li>Enviar los t&eacute;rminos y condiciones de los servicios de asistencia que presta la Empresa.</li>
					<li>
						Gestionar y facilitar procesos de selecci&oacute;n de personal en aras de fomentar y garantizar el empleo
						formal.
					</li>
				</ol>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior a el
					pactado con el tercero o consumidor a trav&eacute;s de la autorizaci&oacute;n para usar sus datos personales,
					y de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la
					informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.9</strong> <strong>Tratamiento de datos personales de Grabador de llamadas.</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta los datos personales de sus Clientes y los almacena en
					una base de datos la cual es calificada por la Empresa como de reserva, y solo ser&aacute; revelada con la
					expresa autorizaci&oacute;n del titular o cuando una Autoridad Competente lo solicite.
				</p>
				<p>
					Las finalidades para las cuales son utilizados los datos personales de los clientes de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> ser&aacute;n:
				</p>
				<ol type="a">
					<li>
						Gesti&oacute;n administrativa para la ejecuci&oacute;n de las etapas precontractual, contractual y pos
						contractual.
					</li>
					<li>
						Garantizar los derechos que le asisten a las personas en virtud del Estatuto del Consumidor (Ley 1480 de
						2011).
					</li>
					<li>Dar tr&aacute;mite a consultas, quejas y reclamos presentados por los clientes o consumidores.</li>
					<li>
						Prestaci&oacute;n de los servicios de asistencia telef&oacute;nica en las l&iacute;neas de hogar, mascotas,
						orientaci&oacute;n m&eacute;dica, veh&iacute;culos, viajes, entre otros.
					</li>
				</ol>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior al
					tiempo que dure la relaci&oacute;n contractual entre el cliente o tercero y la Empresa, y el tiempo adicional
					que se requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la
					informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>9.10</strong> <strong>Tratamiento de datos personales de Accionistas:</strong>
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> recolecta los datos personales de sus Accionistas los almacena en
					una base de datos la cual, es calificada por la compa&ntilde;&iacute;a como de reserva, y que solo los
					revelar&aacute; por la empresa con la expresa autorizaci&oacute;n del titular o cuando una Autoridad
					Competente lo solicite.
				</p>
				<p>Las finalidades para las cuales son utilizadas los datos personales de los Proveedores ser&aacute;n:</p>
				<ol type="a">
					<li>Permitir el ejercicio de los deberes y derechos derivados de la calidad de accionista.</li>
					<li>Permitir el env&iacute;o de invitaciones a eventos programados por la compa&ntilde;&iacute;a.</li>
					<li>Emitir certificaciones relativas a la relaci&oacute;n del Titular con la sociedad.</li>
					<li>
						Dar cumplimiento a los preceptos y normatividad establecida en el C&oacute;digo de Comercio y dem&aacute;s
						normatividad aplicable.
					</li>
					<li> Enviar o tranferir informaci&oacute;n de inter&eacute;s a casa matriz.</li>
				</ol>
				<p>
					En todo caso, la informaci&oacute;n no ser&aacute; objeto de tratamiento por un per&iacute;odo superior a el
					establecido a trav&eacute;s de la autorizaci&oacute;n otorgada por el accionista para usar sus datos
					personales contado a partir de su recolecci&oacute;n de acuerdo con las circunstancias legales o contractuales
					que hacen necesario el manejo de la informaci&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<ol start={10}>
					<li>
						<strong></strong> <strong>TRANSFERENCIA Y TRANSMISI&Oacute;N INTERNACIONAL DE DATOS PERSONALES</strong>
					</li>
				</ol>
				<p>
					Actualmente la Empresa realiza Transferencia Internacional de datos personales relacionados con
					informaci&oacute;n contenida en las bases de datos de proveedores y soportes contables a su casa matriz
					ubicada en <strong>PER&Uacute;</strong>, lo anterior teniendo en cuenta que la misma tiene acceso a los
					sistemas de informaci&oacute;n de <strong>CONNECT ASSISTANCE S.A.S..</strong> Para realizar dicho proceso,{' '}
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> ha verificado que dicho pa&iacute;s re&uacute;na los
					est&aacute;ndares que garantizan un nivel adecuado de protecci&oacute;n de datos personales, encontrando que
					en efecto el numeral 3.2. del Cap&iacute;tulo tercero, del T&iacute;tulo V de la{' '}
					<strong>Circular &Uacute;nica 005 del 10 de agosto de 2017</strong>de la Superintendencia de Industria y
					Comercio incluye a dicho pa&iacute;s en la lista de pa&iacute;ses seguros.
				</p>
				<p>
					Adicionalmente, en caso de que <strong>CONNECT ASSISTANCE S.A.S..,</strong> llegue a realizar
					Transmisi&oacute;n internacional de datos personales, adem&aacute;s de contar con la autorizaci&oacute;n
					expresa e inequ&iacute;voca por parte del Titular, <strong>CONNECT ASSISTANCE S.A.S..,</strong> se
					asegurar&aacute; que la acci&oacute;n proporciona los niveles adecuados de protecci&oacute;n de datos y
					atiende a los requisitos fijados en Colombia por el R&eacute;gimen de Habeas Data.
				</p>
				<p>
					Por otro lado, cuando <strong>CONNECT ASSISTANCE S.A.S..</strong> decida realizar una Transferencia
					Internacional de datos distinta a la previamente mencionada podr&aacute; hacerlo sin autorizaci&oacute;n de
					los titulares, siempre y cuando garantice la seguridad de la informaci&oacute;n, confidencialidad y las
					condiciones que regulen en alcance del tratamiento de los datos, de acuerdo con lo establecido en el
					art&iacute;culo 10 de la Ley 1581 de 2012.
				</p>
				<p>
					<br />{' '}
				</p>
				<ol start={11}>
					<li>
						<strong></strong> <strong>DATOS DE NI&Ntilde;OS, NI&Ntilde;AS Y ADOLESCENTES</strong>
					</li>
				</ol>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..</strong> ,no realiza de forma directa el tratamiento de datos personales de
					menores de edad; sin embargo, de forma particular la Empresa recolecta y trata los datos personales de los
					hijos menores de edad de sus trabajadores, con la &uacute;nica finalidad de cumplir con las obligaciones que
					impone la Ley a los empleadores en relaci&oacute;n con las afiliaciones a sistema de seguridad social y
					parafiscales, as&iacute; como el cumplimiento de la jornada familiar (Ley 1857 de 2017), y en particular para
					permitir el disfrute de los derechos fundamentales de los ni&ntilde;os a la salud, la recreaci&oacute;n y el
					derecho a la familia.
				</p>
				<p>
					En todo caso, <strong>CONNECT ASSISTANCE S.A.S..</strong> recolectar&aacute; cuando corresponda la respectiva
					autorizaci&oacute;n a sus representantes legales para su tratamiento, teniendo siempre de presente el
					inter&eacute;s superior del menor y el respeto de los derechos prevalentes de los ni&ntilde;os, ni&ntilde;as y
					adolescentes consagrados en el articulo 44 de la Constituci&oacute;n Pol&iacute;tica de Colombia.
				</p>
				<ol start={12}>
					<li>
						<strong></strong>{' '}
						<strong>
							PROCEDIMIENTO O PROTOCOLO PARA LA ATENCI&Oacute;N DE CONSULTAS, RECLAMOS Y PETICIONES, Y MECANISMOS PARA
							EJERCER LOS DERECHOS DE LOS TITULARES
						</strong>
					</li>
				</ol>
				<p>
					El Titular, sus causahabientes, su representante y/o apoderado, o quien se determine por estipulaci&oacute;n a
					favor de otro; podr&aacute; hacer ejercicio de sus derechos contact&aacute;ndose con nosotros a trav&eacute;s
					de comunicaci&oacute;n escrita dirigida al &aacute;rea encargada de la protecci&oacute;n de datos personales
					en la compa&ntilde;&iacute;a, <strong>SERVICIO AL CLIENTE.</strong> La comunicaci&oacute;n podr&aacute; ser
					enviada al siguiente correo electr&oacute;nico:{' '}
					<strong>
						<u>protecciondedatos@a365.com.co</u>
					</strong>{' '}
					<strong>,</strong> o a trav&eacute;s de comunicaci&oacute;n escrita radicada la{' '}
					<strong>Carrera 14 #97-63, Piso 4 Oficina 402</strong>
				</p>
				<p>
					<strong>12.1 Consultas</strong>
				</p>
				<p>
					Se podr&aacute; consultar la informaci&oacute;n personal del Titular que repose en las bases de datos de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong>, y la Empresa se encargar&aacute; de suministrar toda la
					informaci&oacute;n contenida en el registro individual o que est&eacute; vinculada con la
					identificaci&oacute;n del solicitante.
				</p>
				<p>
					La consulta una vez recibida por la Empresa ser&aacute; atendida en un t&eacute;rmino m&aacute;ximo de{' '}
					<strong>diez (10) d&iacute;as h&aacute;biles</strong>contados a partir de la fecha de recibo de la misma.
				</p>
				<p>
					Cuando no fuere posible atender la consulta dentro de dicho t&eacute;rmino, se informar&aacute; al interesado,
					expresando los motivos de la demora y se&ntilde;alando la nueva fecha en que se atender&aacute; tal consulta,
					la cual en ning&uacute;n caso podr&aacute; superar los{' '}
					<strong>cinco (5) d&iacute;as h&aacute;biles siguientes</strong> al vencimiento del primer t&eacute;rmino.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>12.2</strong> <strong>Reclamos</strong>
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					Cuando se considere que la informaci&oacute;n contenida en una base de datos de{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong>, debe ser objeto de correcci&oacute;n, actualizaci&oacute;n o
					supresi&oacute;n, o cuando se advierta el presunto incumplimiento de cualquiera de los deberes contenidos en
					la Ley de Habeas Data, se podr&aacute;n presentar reclamaci&oacute;n ante{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong> la cual ser&aacute; tramitada bajo las siguientes reglas:
				</p>
				<ol>
					<li>
						<strong></strong> El reclamo se formular&aacute; mediante comunicaci&oacute;n escrita dirigida a{' '}
						<strong>CONNECT ASSISTANCE S.A.S..</strong>, con la identificaci&oacute;n del Titular, la descripci&oacute;n
						de los hechos que dan lugar al reclamo, la direcci&oacute;n, y acompa&ntilde;ando los documentos que se
						quiera hacer valer.
					</li>
				</ol>
				<p>
					Si el reclamo resulta incompleto, se requerir&aacute; al interesado dentro de los cinco{' '}
					<strong>(5) d&iacute;as</strong> siguientes a la recepci&oacute;n del reclamo para que subsane las fallas.
					Transcurridos dos <strong>(2) meses</strong> desde la fecha del requerimiento, sin que el solicitante presente
					la informaci&oacute;n requerida, se entender&aacute; que ha desistido del reclamo.
				</p>
				<p>
					En caso de que <strong>CONNECT ASSISTANCE S.A.S..</strong> reciba un Reclamo del cual no sea competente para
					resolverlo, la Empresa dar&aacute; traslado a quien efectivamente corresponda en un t&eacute;rmino
					m&aacute;ximo de <strong>dos (2) d&iacute;as h&aacute;biles</strong> e informar&aacute; al Titular.
				</p>
				<ol start={2}>
					<li>
						<strong></strong> Una vez recibido el reclamo completo, la Empresa incluir&aacute; en la respectiva base de
						datos una leyenda que diga <strong>"reclamo en tr&aacute;mite"</strong> y el motivo del mismo, en un
						t&eacute;rmino no mayor a <strong>dos (2) d&iacute;as h&aacute;biles</strong>. La Empresa conservar&aacute;
						dicha leyenda en el dato objeto de discusi&oacute;n hasta que el reclamo sea decidido.
					</li>
					<li>
						<strong></strong> El t&eacute;rmino m&aacute;ximo para atender el reclamo ser&aacute; de{' '}
						<strong>quince (15) d&iacute;as h&aacute;biles</strong> contados a partir del d&iacute;a siguiente a la
						fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho t&eacute;rmino, la Empresa
						informar&aacute; al Titular los motivos de la demora y la nueva fecha en que se atender&aacute; su reclamo,
						la cual en ning&uacute;n caso podr&aacute; superar los <strong>ocho (8) d&iacute;as h&aacute;biles</strong>{' '}
						siguientes al vencimiento del primer t&eacute;rmino.
					</li>
				</ol>
				<p>
					<strong>CONTENIDO M&Iacute;NIMO DE LA SOLICITUD</strong>
				</p>
				<p>
					Las solicitudes que presente el titular con el fin de realizar una consulta o reclamo sobre el uso y manejo de
					sus datos personales deber&aacute;n contener unas especificaciones m&iacute;nimas, en aras de brindar al
					titular una respuesta clara y coherente con lo solicitado. Los requisitos de la solicitud son:
				</p>
				<ol type="a">
					<li>
						Estar dirigida a <strong>CONNECT ASSISTANCE S.A.S..</strong>
					</li>
					<li>Contener la identificaci&oacute;n del Titular (Nombre y Documento de identificaci&oacute;n).</li>
					<li>Contener la descripci&oacute;n de los hechos que motivan la consulta o el reclamo.</li>
					<li>El objeto de la petici&oacute;n.</li>
					<li>
						Indicar la direcci&oacute;n de notificaci&oacute;n del Titular, f&iacute;sica y/o electr&oacute;nica
						(e-mail).
					</li>
					<li>Anexar los documentos que se quieren hacer valer. (Especialmente para reclamos)</li>
				</ol>
				<p>
					En el evento en que la consulta o reclamo sea presentado presencialmente el Titular deber&aacute; plasmar su
					solicitud o reclamo por escrito sin ninguna formalidad m&aacute;s que los requisitos exigidos en el punto
					anterior.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>12.3 Requisito de procedibilidad</strong>
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					El Titular, sus causahabientes, su representante y/o apoderado, o quien se determine por estipulaci&oacute;n a
					favor de otro; s&oacute;lo podr&aacute; presentar queja ante la Superintendencia de Industria y Comercio por
					el ejercicio de sus derechos una vez haya agotado el tr&aacute;mite de Consulta o Reclamo directamente ante la
					Empresa.
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>12.4 Petici&oacute;n de actualizaci&oacute;n y/o rectificaci&oacute;n</strong>
				</p>
				<p>
					<br />{' '}
				</p>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> rectificar&aacute; y actualizar&aacute;, a solicitud del titular,
					la informaci&oacute;n que sea inexacta o se encuentre incompleta, atendiendo al procedimiento y los
					t&eacute;rminos antes se&ntilde;alados, para lo cual el Titular deber&aacute; allegar la solicitud
					seg&uacute;n los canales dispuestos por la compa&ntilde;&iacute;a, indicando la actualizaci&oacute;n y
					rectificaci&oacute;n del dato y a su vez deber&aacute; aportar la documentaci&oacute;n que soporte tal
					petici&oacute;n.
				</p>
				<p>
					<br />{' '}
				</p>
				<ol start={13}>
					<li>
						<strong></strong> <strong>Revocatoria de la autorizaci&oacute;n y/o supresi&oacute;n del Dato</strong>
					</li>
				</ol>
				<p>
					El Titular podr&aacute; revocar en cualquier momento el consentimiento o autorizaci&oacute;n dada para el
					tratamiento de sus datos personales, siempre y cuando no se encuentre un impedimento consagrado en una
					disposici&oacute;n legal o contractual.
				</p>
				<p>
					As&iacute; tambi&eacute;n el Titular tiene derecho a solicitar en todo momento a{' '}
					<strong>CONNECT ASSISTANCE S.A.S..</strong> la supresi&oacute;n o eliminaci&oacute;n de sus datos personales
					cuando:
				</p>
				<ol type="a">
					<li>
						Considere que los mismos no est&aacute;n siendo tratados conforme a los principios, deberes y obligaciones
						previstas en la normatividad vigente.
					</li>
					<li>Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron obtenidos.</li>
					<li>Se haya cumplido el tiempo necesario para el cumplimiento de los fines para los que fueron obtenidos.</li>
				</ol>
				<p>
					Tal supresi&oacute;n implica la eliminaci&oacute;n bien sea de manera total o parcial de la informaci&oacute;n
					personal, de acuerdo con lo solicitado por el titular en los registros, archivos, bases de datos o
					tratamientos realizados por <strong>CONNECT ASSISTANCE S.A.S..</strong>
				</p>
				<p>
					El derecho de cancelaci&oacute;n no es absoluto y por lo tanto <strong>CONNECT ASSISTANCE S.A.S..,</strong>{' '}
					podr&aacute; negar revocatoria de autorizaci&oacute;n o eliminaci&oacute;n de los datos personales en los
					siguientes casos:
				</p>
				<ol type="a">
					<li>El titular tenga un deber legal o contractual de permanecer en la base de datos.</li>
					<li>
						La eliminaci&oacute;n de datos obstaculice actuaciones judiciales o administrativas vinculadas a
						obligaciones fiscales, la investigaci&oacute;n y persecuci&oacute;n de delitos o la actualizaci&oacute;n de
						sanciones administrativas.
					</li>
					<li>
						Los datos sean necesarios para proteger los intereses jur&iacute;dicamente tutelados del titular; para
						realizar una acci&oacute;n en funci&oacute;n del inter&eacute;s p&uacute;blico, o para cumplir con una
						obligaci&oacute;n legalmente adquirida por el titular.
					</li>
				</ol>
				<p>
					<br />{' '}
				</p>
				<ol start={14}>
					<li>
						<strong></strong> <strong>MODIFICACI&Oacute;N DE LAS POL&Iacute;TICAS</strong>
					</li>
				</ol>
				<p>
					<strong>CONNECT ASSISTANCE S.A.S..,</strong> se reserva el derecho de modificar la Pol&iacute;tica de
					Tratamiento y Protecci&oacute;n de Datos Personales en cualquier momento. Sin embargo, toda
					modificaci&oacute;n ser&aacute; comunicada de forma oportuna a los titulares de los datos personales a
					trav&eacute;s de los medios habituales de contacto con diez (10) d&iacute;as h&aacute;biles de
					antelaci&oacute;n a su entrada en vigor.
				</p>
				<p>
					En el evento que un titular no est&eacute; de acuerdo con la nueva Pol&iacute;tica General o especial y con
					razones v&aacute;lidas que se constituyan en una justa causa para no continuar con la autorizaci&oacute;n para
					el tratamiento de datos personales, el Titular podr&aacute; solicitar a la Empresa el retiro de su
					informaci&oacute;n a trav&eacute;s de los canales indicados en el Cap&iacute;tulo 12. Sin embargo, los
					Titulares no podr&aacute;n solicitar el retiro de sus datos personales cuando la Empresa tenga un deber legal
					o contractual de tratar los datos.
				</p>
				<p>
					<br />{' '}
				</p>
				<ol start={15}>
					<li>
						<strong></strong> <strong>VIGENCIA</strong>
					</li>
				</ol>
				<p>La presente Pol&iacute;tica rige a partir de agosto de 2020</p>
				<p>
					<br />{' '}
				</p>
				<p>CREACION DEL DOCUMENTO</p>
				<p>06-06-2019</p>
				<p>JEFATURA DE SISTEMAS E INFRAESTRUCTURA &ndash; ASESOR EXTERNO</p>
				<p>
					<span>&nbsp;</span>
				</p>
			</div>
			<div className="hero dark ctr">
				<div className="container narrow">
					<h1>
						<OrangeTitle className="ctr">Contacto</OrangeTitle>
					</h1>
					<h2>¿Toadavía tienes preguntas?</h2>
					<p className="detailJob">No te preocupes. Estamos aquí para asistir.</p>
					<a href="mailto:assist@fulcroinsurance.com" className="button ctr">
						Contactanos
					</a>
				</div>
			</div>
		</div>
	);
};
export default PoliticasHabeasData;
