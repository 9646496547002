import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import './header-mobile.scss';
import closeBtn from '../../../images/svg/close-btn.svg';

import { useTranslation } from 'react-i18next';
import segurosFulcroLogo from '../../../images/svg/seguros-fulcro-logo.svg';
import FooterLogos from 'components/footer-logos/footer-logos';
import { NavHashLink } from 'react-router-hash-link';

export default function HeaderMobile() {
	const { t } = useTranslation();
	const [isNavVisible, setNavVisibility] = useState(false);

	const targetScroll: any = null;

	const headerActiveClass = classNames(
		'header--mobile', // default classes on <nav>
		{
			active: isNavVisible, // classes added to <nav> when opened
		}
	);

	const disableScroll = () => {
		document.body.style.overflow = 'hidden';
	};

	const enableScroll = () => {
		document.body.style.overflow = targetScroll;
	};

	const closeMenu = () => {
		setNavVisibility(false);
		enableScroll();
	};

	const toggleNav = () => {
		disableScroll();
		setNavVisibility(!isNavVisible);
		if (isNavVisible) {
			closeMenu();
		}
	};

	const emergenciaClose = () => {
		document.body.classList.add('emergencia-closed');
	};

	return (
		<header className={headerActiveClass}>
			<div className="emergencia toast">
				<div className="emergencia__title">¿Tienes una emergencia?</div>
				<div className="emergencia__links">
					<a href={t('phoneLink')} className="icon-btn phone">
						Chat
					</a>
					<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')} className="icon-btn chat">
						Chat
					</a>
					<div className="link close" onClick={emergenciaClose}>
						<img src={closeBtn} alt="close" />
					</div>
				</div>
			</div>

			<div className="header__bar">
				<div className="menu-toggle">
					<i onClick={toggleNav} className={!isNavVisible ? 'open' : 'hidden'}></i>
					<i onClick={toggleNav} className={isNavVisible ? 'close' : 'hidden'}></i>
				</div>

				<div>
					<Link
						onClick={closeMenu}
						to={{ pathname: 'https://fulcroinsurance.com/fulcro-assist/' }}
						target="_top"
						className="logo"
					>
						<img className="img__nav" src={segurosFulcroLogo} alt="Connect Assistance Map Marker Icon" />
					</Link>
				</div>
			</div>

			<nav>
				<div className="nav__top">
					<div className="main-menu">
						<ul>
							<li className="dd" onClick={closeMenu}>
								<Link onClick={closeMenu} to={'/servicios-shared/auto'}>
									<label htmlFor="drop-1">Asistencia para autos</label>
								</Link>
							</li>
							<li className="dd" onClick={closeMenu}>
								<Link onClick={closeMenu} to={'/servicios-shared/hogar'}>
									<label htmlFor="drop-1">Asistencia en tu hogar</label>
								</Link>
							</li>
							{/* <li className="dd" onClick={closeMenu}>
								<NavHashLink
									to="/#servicios"
									scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
									onClick={closeMenu}
								>
									<label htmlFor="drop-1">Comprar plan</label>
								</NavHashLink>
							</li> */}
						</ul>
					</div>
				</div>
				<FooterLogos />
			</nav>
		</header>
	);
}
