interface Count {
	auto: number;
	moto: number;
	home: number;
	pago: string;
	branch: string;
}

const getPrice = (data: Count) => {
	let autoAnualPrice = 0;
	let motoAnualPrice = 0;
	let homeAnualPrice = 0;
	let autoMensualPrice = 0;
	let motoMensualPrice = 0;
	let homeMensualPrice = 0;
	let autoPlusOne = 0;
	let motoPlusOne = 0;
	let homePlusOne = 0;

	switch (data.branch) {
		case 'pr':
			autoAnualPrice = 55;
			motoAnualPrice = 55;
			homeAnualPrice = data.home > 1 ? 79.8 : 89;
			autoMensualPrice = 5.99;
			motoMensualPrice = 5.99;
			homeMensualPrice = 9.5;
			autoPlusOne = 27.5;
			motoPlusOne = 27.5;
			homePlusOne = data.home > 1 ? 79.8 : 89;
			break;
		case 'cr':
			autoAnualPrice = 39;
			motoAnualPrice = 39;
			autoMensualPrice = 4;
			motoMensualPrice = 4;
			autoPlusOne = 19.5;
			motoPlusOne = 19.5;
			break;
		case 'co':
			autoAnualPrice = 204000;
			motoAnualPrice = 204000;
			autoMensualPrice = 20000;
			motoMensualPrice = 20000;
			autoPlusOne = 102000;
			motoPlusOne = 102000;
			break;

		default:
			break;
	}

	if (data.auto >= 1) {
		// Hago validacion de si Home tiene mas de 1 ya que Home tiene el precio mas alto para el Bundle
		if (data.home >= 1) {
			autoAnualPrice = 0;
			autoAnualPrice += autoPlusOne * data.auto;
		} else if (data.auto > 1) {
			autoAnualPrice += data.auto >= 2 ? autoPlusOne * (data.auto - 1) : autoAnualPrice;
		} else if (data.auto === 0) {
			autoAnualPrice = 0;
		}
		autoMensualPrice = autoMensualPrice * data.auto;
	} else if (data.auto === 0) {
		autoAnualPrice = 0;
		autoMensualPrice = 0;
		autoPlusOne = 0;
	}

	if (data.moto >= 1) {
		if (data.auto >= 1 || data.home >= 1) {
			motoAnualPrice = 0;
			motoAnualPrice += motoPlusOne * data.moto;
		} else if (data.moto > 1) {
			motoAnualPrice += data.moto >= 2 ? motoPlusOne * (data.moto - 1) : motoAnualPrice;
		} else if (data.moto === 0) {
			motoAnualPrice = 0;
		}
		motoMensualPrice = motoMensualPrice * data.moto;
	} else if (data.moto === 0) {
		motoAnualPrice = 0;
		motoMensualPrice = 0;
		autoPlusOne = 0;
	}

	if (data.home >= 1) {
		if (data.home > 1) {
			homeAnualPrice += data.home >= 2 ? homePlusOne * (data.home - 1) : homeAnualPrice;
		} else if (data.home === 0) {
			homeAnualPrice = 0;
		}
		homeMensualPrice = homeMensualPrice * data.home;
	} else if (data.home === 0) {
		homeAnualPrice = 0;
		homeMensualPrice = 0;
		autoPlusOne = 0;
	}

	const totalAnual = autoAnualPrice + motoAnualPrice + homeAnualPrice;
	const mensualAnual = autoMensualPrice + motoMensualPrice + homeMensualPrice;
	const anualPerc = Math.floor((1 - totalAnual / (mensualAnual * 12)) * 100);

	return {
		autoAnualPrice: autoAnualPrice,
		motoAnualPrice: motoAnualPrice,
		homeAnualPrice: homeAnualPrice,
		autoMensualPrice: autoMensualPrice,
		motoMensualPrice: motoMensualPrice,
		homeMensualPrice: homeMensualPrice,
		anualPrice: autoAnualPrice + motoAnualPrice + homeAnualPrice,
		anualPerc: anualPerc,
		mensualPrice: autoMensualPrice + motoMensualPrice + homeMensualPrice,
		mensualPerc: 0,
	};
};

const calculate = (data: Count) => {
	const totalData = getPrice(data);
	return totalData;
};

const formatPrice = (value: number, branch: string, digits: number): string => {
	if (!value) {
		return '';
	}
	const locales = {
		co: 'es',
		pr: 'en',
		cr: 'en',
		pty: 'en',
	}[branch];
	const fractionDigits = branch === 'co' ? 0 : digits;
	return value.toLocaleString(locales, {
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	});
};

export default { getPrice, calculate, formatPrice };
