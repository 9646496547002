import React, { useRef } from 'react';
import './incluye.scss';

interface IProps {
	template: 'primary' | 'secondary';
	data: { li: string; detail: string }[];
}

const Incluye: React.FC<IProps> = ({ template, data }) => {
	const ulRef = useRef(null);
	const handleShowDetail = (id: number) => {
		(ulRef.current as any).children[id].classList.toggle('show');
		(ulRef.current as any).children[id].children[0].classList.toggle('show');
	};
	return (
		<div className="incluye__container">
			<h3 className={template}>Incluye:</h3>
			<ul className={template} ref={ulRef}>
				{data.map((item, index) => (
					<li
						className={item.detail ? 'pointer' : ''}
						id={'li' + index}
						onClick={() => handleShowDetail(index)}
						key={'li' + index}
					>
						{item.li}
						<span key={'li-span' + index} id={'li-span' + index} className="span__detail">
							{item.detail}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Incluye;
