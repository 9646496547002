import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import './primary-content.scss';

import Container from '../container/container';
import { Link } from 'react-router-dom';
import { getFunnelUrl } from '../../helpers/FunnelRedirection';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';

interface Props {
	title: string;
	subTitle: string;
}

const SlideContent: React.FC<Props> = ({ title, subTitle }) => {
	const { t } = useTranslation();
	const divStyle = {
		backgroundImage: `url('${t('slideContentImg')}')`,
	};
	const linkTo = getFunnelUrl();
	const goToSection = () => {
		const element = document.querySelector('#servicios');
		element?.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<>
			<div className="banner__primary ctr" style={divStyle}>
				<Container>
					<div className="slide-content">
						<h1
							className="h1"
							dangerouslySetInnerHTML={{
								__html: title,
							}}
						></h1>
						<p className="slide-p-padding">{subTitle}</p>

						<Link
							to="/#servicios"
							className="mas-info underline"
							onClick={goToSection}
						>
							Conocer más
						</Link>
						<Link
							to="/#servicios"
							className="button ctr"
							onClick={goToSection}
						>
							Cotizar y comprar
						</Link>
					</div>
				</Container>
			</div>
		</>
	);
};

export default SlideContent;
